<template>
  <div class="screen">
    <v-row>
      <v-col cols="10"></v-col>
      <v-col cols="2">
        <v-btn right @click="$router.back()" class="primary justify-end">
          <v-icon>mdi-keyboard-backspace</v-icon>
          <span class="px-2">Back</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" xlg="6" class="mx-auto">
        <v-card class="pa-3">
          <v-card-header class="text-center">
            <v-row>
              <v-col cols="grow">
                <p class="display-1 py-2">
                  {{ filteredModules.length }} Modules
                </p>
                <v-text-field
                  class="text-center mx-auto text-xs-center"
                  color="secondary"
                  label="Search"
                  solo
                  flat
                  block
                  prepend-inner-icon="mdi-magnify"
                  v-model="txtSearch"
                ></v-text-field>
              </v-col>
              <v-col cols="auto" class="my-auto">
                <v-btn icon @click="refresh()">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-header>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="mx-auto my-auto" cols="12" sm="12" md="3" lg="3">
        <v-btn
          rounded
          @click="downloadCsvContent('Modules', filteredModules, txtSearch)"
          outlined
          block
          color="primary"
        >
          <v-icon>mdi-download</v-icon> Download
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10" class="mx-auto">
        <v-data-table
          :headers="headers"
          :items="filteredModules"
          class="elevation-1"
          item-key="_id"
          :items-per-page="-1"
          @click:row="(arg) => goToModule(arg._id)"
          :loading="isLoading"
          :search="txtSearch"
          loading-text="Loading... Please wait"
        ></v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import swal from "sweetalert2";

const axios = require("axios");

export default {
  name: "StidentList",
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Code",
          align: "left",
          sortable: true,
          value: "code",
        },
        {
          text: "Description",
          align: "left",
          sortable: true,
          value: "description",
        },
        {
          text: "Lecturers",
          align: "left",
          sortable: true,
          value: "lecturerCount",
        },
        {
          text: "Students",
          align: "left",
          sortable: true,
          value: "studentCount",
        },
        {
          text: "Assessments",
          align: "left",
          sortable: true,
          value: "questionaireCount",
        },
        {
          text: "Notes",
          align: "left",
          sortable: true,
          value: "notesCount",
        },
        {
          text: "Announcements",
          align: "left",
          sortable: true,
          value: "announcementCount",
        },
      ],
      txtSearch: "",
      txtError: "",
      modules: [],
      selectedStudent: null,
      isLoading: false,
    };
  },
  computed: {
    filteredModules() {
      if (!this.modules) return [];
      return this.modules.map((v) => {
        return {
          ...v,
          lecturerDetails: v.lecturers
            ? v.lecturers
                .filter((b) => b.lastname && b.firstname)
                .reduce((a, b) => a + `${b.lastname} ${b.firstname} , `, "")
            : null,
          totalStudents: v.students ? v.students.length : null,
          totalOnlineTests: v.questionaires ? v.questionaires.length : null,
        };
      });
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.getAllMyModules();
    },
    goToSolution(solutionId) {
      this.$router.push({
        name: "TestMarks",
        params: {
          solutionId: solutionId,
        },
      });
    },
    async getAllMyModules() {
      try {
        if (!this.$store.state.currentUser) {
          return this.$dialog.fire(
            "Unable to get modules",
            "You have to be logged in to perform this action",
            "error"
          );
        }
        if (!this.$store.state.currentSchool) {
          return this.$dialog.fire(
            "Unable to get modules",
            "A school must be selected to perform this action",
            "error"
          );
        }
        this.isLoading = true;
        this.modules = await this.$api.getAllMyModules({
          schoolUrl: this.$store.state.currentSchool.url,
          filter: "name code description",
        });
        this.isLoading = false;
        console.log(this.modules);
      } catch (err) {
        this.$dialog.fire("Unable to load modules", err.message, "error");
        this.isLoading = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.screen {
  background-image: url("/static/img/coPortalLogo.jpg");
  /* Full height */
  height: 100vh;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: fit;
}
</style>
