
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      url: "",
      txtError: "",
    };
  },
  props: {
    ftpId: String,
    title: String,
    height: {
      type: String,
      default: "100%",
    },
    width: {
      type: String,
      default: "100%",
    },
  },
  watch: {
    ftpId(newVal, oldVal) {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.txtError = "";
      try {
        this.url = await this.$api.getDownloadURL(this.ftpId);
      } catch (err) {
        this.txtError = err.message;
      }
    },
    download() {
      this.$api.createNewUsageLog({
        schoolId: this.$store.state.currentSchool?._id,
        platform: "WEB",
        event: "DOWNLOADED_CONTENT",
        description: `Downloaded a file, ${this.title}`,
        metadata: {
          url: this.url,
          ftpId: this.ftpId,
        },
      });

      const a = document.createElement("a");
      a.target = "_blank";
      a.href = this.url;
      a.click();
      a.remove();
    },
  },
});
