import axios from "axios";
import router from "@/router";
import store from "@/store";

export default class HTTP {
  constructor() {
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        console.log("Intercepted....", JSON.parse(JSON.stringify(err)));
        throw err;
      });
    });
    this.init();
  }

  public async init() {
    await store.restored;
    const { currentToken } = store.state;
    if (currentToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${currentToken}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
    this.isAuthorized();
  }

  public makeURL(url: string): string {
    return store.state.settings.baseLink + url;
  }

  public makeGet(url: string) {
    return axios.request({
      url: this.makeURL(url),
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  public makeFileUpload(url: string, formData: FormData) {
    const { name:title } = formData.get('file') as File;
        
    return axios.request({
      url: this.makeURL(url),
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      data: formData,
      onUploadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        
        store.commit('setCurrentUploadProgress',{
          title,
          progress
        })
      }     
    });
  }

  public makePost(url: string, content = {}) {
    return axios.request({
      url: this.makeURL(url),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      data: JSON.stringify(content),
    });
  }

  public isAuthorized() {
    this.makeGet("/auth-service/test")
      .then((v) => {
        console.log("Is authorized.");
        console.log("Success", v);
      })
      .catch((err) => {
        console.log("Is not authorized.", err);
        this.logout();
      });
  }

  public async authorize({ token, user, school }: any) {
    store.commit("setAuthToken", token.accessToken);
    store.commit("setCurrentUser", user);
    store.commit("setCurrentSchool", school);
    axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`;
  }

  public getMyProfileForSchool(schoolUrl: string) {
    return new Promise((resolve, reject) => {
      this.makeGet(`/auth-service/get/my/profile/for/school/${schoolUrl}`)
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public login(username: string, password: string, schoolUrl: string) {
    return new Promise((resolve, reject) => {
      this.makePost("/auth-service/login", {
        username: username,
        password: password,
        schoolUrl: schoolUrl,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public logout() {
    store.commit("setAuthToken", null);
    store.commit("setCurrentUser", null);
    store.commit("setCurrentSchool", null);
    delete axios.defaults.headers.common.Authorization;

    router.replace("/login");
  }

  public switchTenant() {
    store.commit("setCurrentTenant", null);
    localStorage.removeItem("tenant_user");
    router.go(0);
  }

  public getAllStudentsWithModules() {
    return new Promise((resolve, reject) => {
      this.makeGet("/api/v2/student/get/all")
        .then((v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          return reject(
            new Error("Unable to process your request, try again later")
          );
        });
    });
  }

  public registerToMyNetwork(userObj: any, userType: "LECTURER" | "STUDENT") {
    return new Promise((resolve, reject) => {
      function generatePassword(length: number) {
        const charactors =
          "1234567890qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM_-&!;";
        let password = "";
        for (let i = 0; i < length; i++) {
          password +=
            charactors[Math.floor(Math.random() * (charactors.length - 1))];
        }
        return password;
      }
      const obj = {
        password: generatePassword(12),
        firstname: userObj.firstname,
        lastname: userObj.lastname,
        username: userObj.username,
      };

      axios
        .post(
          `https://jmrsquared.com/api/coportal/mynetwork/api/v2/a/add/${userType.toLowerCase()}`,
          obj
        )
        .then((v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          return reject(
            new Error("Unable to process your request, try again later")
          );
        });
    });
  }

  public getAllDBModules() {
    return new Promise((resolve, reject) => {
      this.makeGet("/api/v3/module/get/list")
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public getAllMyModules({
    schoolUrl,
    filter,
  }: {
    schoolUrl: string;
    filter: string;
  }) {
    return new Promise((resolve, reject) => {
      this.makePost("/api/v3/module/get/all", {
        schoolUrl,
        filter,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public getModuleById(schoolUrl: string, moduleId: string) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/module/get/by/id`, {
        moduleId,
        schoolUrl,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public lookupIds(
    collection:
      | "announcements"
      | "questionaires"
      | "notes"
      | "students"
      | "lecturers"
      | "admins"
      | "modules",
    ids: string[],
    filter: string
  ) {
    return new Promise((resolve, reject) => {
      console.log({ collection, ids, filter });
      this.makePost(`/api/v3/module/lookup/ids/from/collections`, {
        collection,
        ids,
        filter,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public downloadNotes(ftpId: string, filename: string) {
    return new Promise((resolve) => {
      const newWindow = window.open(
        this.makeURL(`/ftp-service/get/file/${ftpId}`),
        "_blank"
      );
      if (newWindow) {
        newWindow.document.title = filename;
      }
      return resolve(true);
    });
  }

  public getDownloadURL(ftpId: string) {
    return this.makeURL(`/ftp-service/get/file/${ftpId}`);
  }

  public getSchoolsAsAdmin() {
    return new Promise((resolve, reject) => {
      this.makeGet(`/api/v3/school/get/as/admin`)
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public createNewSchool(school: any) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/school/create`, {
        school,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public updateSchoolDetails(school: any) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/school/update`, {
        school,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public deleteSchool(schoolId: string) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/school/delete`, {
        schoolId,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public removeFromSchool(
    collection: "modules" | "lecturers" | "students" | "admins",
    schoolId: string,
    ids: string[]
  ) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/school/unlink/bulk`, {
        collection,
        schoolId,
        ids,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public addToSchool(
    collection: "modules" | "lecturers" | "students" | "admins",
    schoolId: string,
    items: any[],
    // optionally for when you wanna link the new items to modules
    moduleIds?: string[]
  ) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/school/link/bulk`, {
        collection,
        schoolId,
        items,
        moduleIds,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public saveNotes(noteDetails: {
    title: string;
    description: string;
    isDownloadable: boolean;
    moduleId: string;
    schoolUrl: string;
    ftpId: string;
  }) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/module/save/notes`, {
        ...noteDetails,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public downloadFile(fileId: string) {
    return new Promise((resolve, reject) => {
      this.makeGet(`/ftp-service/get/file/${fileId}`)
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public uploadFile(file: any) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);

      this.makeFileUpload(`/ftp-service/save/file`, formData)
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public createQuestionaire(questionaire: {
    title: string;
    questions: any;
    timeLimit: string;
    attemptLimit: string;
    schoolUrl: string;
    moduleId: string;
  }) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/questionaire/create`, {
        ...questionaire,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public submitQuestionaire(solution: {
    answers: Array<any>;
    isMemo: boolean;
    questionaireId: string;
  }) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/questionaire/submit`, {
        ...solution,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public getQuestionaireSolutions(questionaireId: string, allOrMy: string) {
    return new Promise((resolve, reject) => {
      this.makeGet(
        `/api/v3/questionaire/get/${allOrMy}/solutions/for/${questionaireId}`
      )
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public downloadBulkSolutions(props: { solutionIds: string[] }) {
    const { solutionIds } = props;

    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/questionaire/download/bulk/solutions`, {
        solutionIds,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public submitMarkedSolution(solution: {
    _id: string;
    questionaireId: string;
    results: Array<any>;
  }) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/questionaire/submit/marked/solution`, {
        ...solution,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public createNewAnnouncement(announcement: {
    title: string;
    message: string;
    moduleIds: string[];
    schoolUrl: string;
    isToParents: boolean;
  }) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/announcement/create`, {
        ...announcement,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public createNewUsageLog(usageLog: {
    schoolId: string;
    platform: string;
    event:
      | "ERROR"
      | "WROTE_TEST"
      | "VISITED_MY_NETWORK"
      | "DOWNLOADED_CONTENT"
      | "LOGIN"
      | "OPEN_ANNOUNCEMENT"
      | "ADDED_NOTES"
      | "ADDED_ANNOUNCEMENT"
      | "REMOVED_FROM_SCHOOL"
      | "ADDED_TO_SCHOOL"
      | "ADDED_SCHOOL"
      | "UPDATED_SCHOOL"
      | "VISITED_MODULE"
      | "ADDED_TEST";
    description: string;
    metadata: any;
    noAuth?: boolean;
  }) {
    return new Promise((resolve, reject) => {
      const url = usageLog.noAuth ? "create/no-auth" : `create`;
      this.makePost(`/api/v3/usage-log/${url}`, {
        ...usageLog,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public getUsageReport(usageReport: {
    schoolIds: string[];
    dates: { start: string; end: string }[];
  }) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/usage-log/generate`, {
        ...usageReport,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public getFullUsageReport(usageReport: {
    schoolIds: string[];
    dates: { start: string; end: string }[];
    events: string[];
  }) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/usage-log/generate/full/report`, {
        ...usageReport,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public createNewMyNetwork(myNetwork: {
    schoolId: string;
    userRole: string;
    title: string;
    modules: string[];
    description: string;
    ftp: {
      files: {
        ftpId: string;
        originalname: string;
        contentType: string;
      }[];
      attachments: {
        ftpId: string;
        originalname: string;
        contentType: string;
      }[];
    };
    isToAllSchools: boolean;
  }) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/my-network/create`, {
        ...myNetwork,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public addCommentToMyNetwork(props: {
    myNetworkId: string;
    message: string;
    userRole: string;
    schoolId: string;
  }) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/my-network/comment`, {
        ...props,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public addLikeToMyNetwork(props: {
    myNetworkId: string;
    userRole: string;
    schoolId: string;
  }) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/my-network/like`, {
        ...props,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public getMyNetworkById(props: { myNetworkId: string }) {
    return new Promise((resolve, reject) => {
      this.makeGet(`/api/v3/my-network/get/by/id/${props.myNetworkId}`)
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public deleteMyNetworkById(props: { myNetworkId: string }) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/my-network/delete`, {
        ...props,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }

  public getMyNetworks(props: {
    moduleIds: string[];
    school: string;
    skip: number;
    take: number;
    search?: string
  }) {
    return new Promise((resolve, reject) => {
      this.makePost(`/api/v3/my-network/get/all`, {
        ...props,
      })
        .then(async (v) => {
          if (v.data && v.data.status) {
            return resolve(v.data.data);
          } else if (v.data && !v.data.status) {
            return reject(new Error(v.data.message));
          } else {
            return reject(
              new Error("An unknown error occured, try again later.")
            );
          }
        })
        .catch((err) => {
          console.log({ err });
          return reject(new Error("Unexpected error, Try again later."));
        });
    });
  }
}
