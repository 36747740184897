var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{model:{value:(_vm.showingSurveyStudentList),callback:function ($$v) {_vm.showingSurveyStudentList=$$v},expression:"showingSurveyStudentList"}},[_c('v-card',{staticClass:"pa-3",attrs:{"loading":!_vm.selectedSurveyStudentList}},[(
          _vm.selectedSurveyStudentList &&
            _vm.selectedSurveyStudentList.surveyTemplateId
        )?_c('v-card-header',[_c('v-card-title',{staticClass:"display-1"},[_vm._v(_vm._s(_vm.selectedSurveyStudentList.surveyTemplateId.title))])],1):_vm._e(),(_vm.selectedSurveyStudentList)?_c('v-card-content',[_c('v-row',_vm._l((_vm.selectedSurveyStudentList.students.filter(
              v => v && v.studentId
            )),function(student,i){return _c('v-col',{key:i,staticClass:"mx-auto",attrs:{"cols":"12","sm":"12","md":"10"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-header',{staticClass:"col s12 m-10 p-x-10 p-t-10"},[_c('span',{staticClass:"title"},[_c('strong',[_vm._v(_vm._s(student.studentId.lastname)+" "+_vm._s(student.studentId.firstname))])])]),_c('v-card-content',{staticClass:"col s12"},[_c('v-list',_vm._l((student.answers),function(studentAnswer,k){return _c('v-list-item',{key:k},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-forum")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(studentAnswer.question))]),_c('v-list-item-subtitle',{staticClass:"secondary--text"},[_vm._v(_vm._s(studentAnswer.answer))])],1)],1)}),1)],1)],1)],1)}),1)],1):_vm._e(),_c('v-btn',{staticClass:"primary",on:{"click":function($event){_vm.showingSurveyStudentList = false}}},[_vm._v("Close")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}}),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"primary justify-end",attrs:{"right":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-keyboard-backspace")]),_c('span',{staticClass:"px-2"},[_vm._v("Back")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","sm":"12","md":"4","md-offset":"4"}},[_c('h2',{staticClass:"text-center display-1"},[_vm._v("Surveys")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"row"},_vm._l((_vm.modules),function(_module,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"12","md":"6","xl":"4"}},[_c('v-card',{staticClass:"pa-4",attrs:{"loading":!_module.loaded}},[_c('v-card-header',[_c('v-row',{staticClass:"px-5"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_module.name)+" "+_vm._s(_module.code))]),_c('v-spacer'),(
                    _module.surveyTemplates &&
                      _module.surveyTemplates.length > 0
                  )?_c('v-menu',{attrs:{"open-on-hover":"","top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"large":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('v-list',_vm._l((_module.surveyTemplates),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.createSurvey(_module, item._id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1):_vm._e()],1)],1),_c('v-card-content',[(_module.loaded && _module.moduleSurveys)?_c('v-list',{staticClass:"v-double-line"},_vm._l((_module.moduleSurveys),function(moduleSurvey,k){return _c('v-list-item',{key:k,on:{"click":function($event){return _vm.selectModuleSurvey(_module._id, moduleSurvey)}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-forum")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(moduleSurvey.surveyTemplateId.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.getMoment(moduleSurvey.date).format("Do MMMM YYYY")))])],1)],1)}),1):_vm._e()],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }