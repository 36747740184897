
import Vue from "vue";

export default Vue.extend({
  computed: {
    overrallProgress() {
      return Math.floor(
        this.$store.state.currentUploads.reduce((a, b) => a + b.progress, 0) /
          this.$store.state.currentUploads.length
      );
    },
  },
  methods: {
    clearUploads() {
      this.$store.commit("clearCurrentUploads");
    },
  },
});
