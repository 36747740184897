
import Vue from "vue";
import CreateMyNetwork from "./CreateMyNetwork.vue";
// TODO: ADD Proper pagination
const ITEMS_PER_PAGE = 10000;

export default Vue.extend({
  name: "MyNetwork",
  data() {
    return {
      selectedModules: [],
      allModules: [],
      isLoading: false,
      page: 0,
      posts: [],
      showCreateMyNetwork: false,
      txtSearch: "",
    };
  },
  components: {
    CreateMyNetwork,
  },
  mounted() {
    this.refresh();
  },
  watch: {
    txtSearch(newVal, oldVal) {
      if (newVal != oldVal) {
        this.debounceGetAllMyNetworks();
      }
    },
  },
  methods: {
    copyLinkToPost(postId) {
      let url = window.location.href;
      if (!url.includes(postId)) {
        url += `/view/${postId}`;
      }
      if (!navigator.clipboard) {
        alert(url);
        return;
      }
      navigator.clipboard.writeText(url);
      this.$dialog.fire({
        position: "bottom-center",
        showConfirmButton: false,
        timer: 3500,
        title: "Link copied to clipboard",
        text: url,
        icon: "success",
      });
    },
    goToPost(postId) {
      this.$router.push(`/my-network/view/${postId}`);
    },
    closedCreateMyNetworkDialog(mustRefresh) {
      this.showCreateMyNetwork = false;
      if (mustRefresh) {
        this.refresh();
      }
    },
    async debounceGetAllMyNetworks() {
      clearTimeout(this.getAllMyNetworksDebounceTimer);
      this.getAllMyNetworksDebounceTimer = setTimeout(() => {
        this.getAllMyNetworks();
      }, 1500);
    },
    async getAllMyNetworks() {
      this.isLoading = true;
      try {
        this.posts = await this.$api.getMyNetworks({
          moduleIds: this.selectedModules,
          school: this.$store.state.currentSchool?._id,
          skip: ITEMS_PER_PAGE * this.page,
          take: ITEMS_PER_PAGE,
          search: this.txtSearch,
        });
      } catch (err) {
        this.$dialog.fire("Unable to get posts", err.message, "error");
      }
      this.isLoading = false;
    },
    async refresh() {
      this.isLoading = true;
      try {
        // Query getAllMyModules if we have a school, or getAllDBModules if we dont have
        const schoolUrl =
          this.$store.state.currentUser && this.$store.state.currentSchool?.url;
        if (schoolUrl) {
          this.allModules = (
            await this.$api.getAllMyModules({
              schoolUrl,
              filter: "code name description",
            })
          ).map((v) => ({
            ...v,
            title: `${v.name} ${v.code}`,
          }));
        } else {
          this.allModules = (await this.$api.getAllDBModules()).map((v) => ({
            ...v,
            title: `${v.name} ${v.code}`,
          }));
        }

        if (this.selectedModules.length == 0) {
          this.selectedModules = this.allModules.map((v) => v._id);
        }

        await this.getAllMyNetworks();

        const user = this.$store.state.currentUser;
        const userString = user
          ? `${user.firstname} ${user.lastname}`
          : "Anonymus";

        this.$api.createNewUsageLog({
          schoolId: this.$store.state.currentSchool?._id,
          platform: "WEB",
          event: "VISITED_MY_NETWORK",
          description: `${userString} visited the my_network tab`,
          metadata: {
            userId: this.$store.state.currentUser?._id,
          },
          noAuth: !(
            this.isCurrentAdmin ||
            this.isCurrentLecturer ||
            this.isCurrentStudent
          ),
        });
      } catch (err) {
        this.$dialog.fire({
          title: err.message,
          text: "Unable to get posts",
          icon: "error",
        });
      }
      this.isLoading = false;
    },
    downloadFile(ftpId, title) {
      const url = this.$api.getDownloadURL(ftpId);

      this.$api.createNewUsageLog({
        schoolId: this.$store.state.currentSchool?._id,
        platform: "WEB",
        event: "DOWNLOADED_CONTENT",
        description: `Downloaded a file, via MyNetwork ${title}`,
        metadata: {
          url: url,
          ftpId: ftpId,
        },
      });

      const a = document.createElement("a");
      a.target = "_blank";
      a.href = url;
      a.click();
      a.remove();
    },
    async like(post) {
      const index = post.likes.findIndex(
        (v) => v && v.user == this.$store.state.currentUser?._id
      );
      if (index >= 0) {
        post.likes.splice(index, 1);
      } else {
        post.likes.unshift({
          date: new Date(),
          role: this.$store.state.currentSchool?.role,
          school: this.$store.state.currentSchool?._id,
          user: this.$store.state.currentUser?._id,
        });
      }

      try {
        await this.$api.addLikeToMyNetwork({
          myNetworkId: post._id,
          userRole: this.$store.state.currentSchool?.role,
          schoolId: this.$store.state.currentSchool?._id,
        });
      } catch (err) {
        console.log({ err });
      }
    },
    async comment(post) {
      if (post.newComment.length < 2) {
        return;
      }
      const comment = post.newComment;
      post.newComment = "";
      post.comments.unshift({
        date: new Date(),
        role: this.$store.state.currentSchool?.role,
        school: this.$store.state.currentSchool?._id,
        user: this.$store.state.currentUser?._id,
        message: comment,
      });
      try {
        await this.$api.addCommentToMyNetwork({
          myNetworkId: post._id,
          message: comment,
          userRole: this.$store.state.currentSchool?.role,
          schoolId: this.$store.state.currentSchool?._id,
        });
        this.$dialog.fire({
          position: "bottom-center",
          showConfirmButton: false,
          timer: 1500,
          title: "Comment sent",
          icon: "success",
        });
      } catch (err) {
        console.log({ err });
      }
    },
    async deletePost(postId) {
      const response = await this.$dialog.fire({
        title: `Are you sure?`,
        text: "This can not be reverted",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.primary,
        cancelButtonColor: this.$vuetify.theme.themes.light.error,
        confirmButtonText: "Yes",
      });
      if (!response.value) {
        return;
      }

      try {
        const index = this.posts.findIndex((v) => v._id == postId);
        if (index >= 0) {
          this.posts.splice(index, 1);
        }
        this.isLoading = true;
        await this.$api.deleteMyNetworkById({
          myNetworkId: postId,
        });
      } catch (err) {
        this.$dialog.fire({
          title: "Unable to delete post",
          message: err.message,
        });
      }
      this.isLoading = false;
    },
  },
});
