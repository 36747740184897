export const samples = {
    "editContentSample": {
      "modules": [
        {
          "name": "Maths",
          "code": "Grade 08",
          "description": "Grade 08 Mathematics"
        },
        {
          "name": "Maths",
          "code": "Grade 09",
          "description": "Grade 09 Mathematics"
        },
        {
          "name": "English",
          "code": "Grade 10",
          "description": "Grade 10 English"
        }
      ],
      "lecturers": [
        {
          "firstname": "John",
          "lastname": "Smith",
          "username": "john_smith",
          "idNumber": "9109056037088",
          "numbers": "0760487299",
          "email": "john@smith.com",
          "password": "john_123"
        }
      ],
      "students": [
        {
          "firstname": "John",
          "lastname": "Smith",
          "username": "john_smith",
          "idNumber": "9109056037088",
          "numbers": "0760487299",
          "email": "john@smith.com",
          "password": "john_123"
        }
      ],
      "admins": [
        {
          "firstname": "John",
          "lastname": "Smith",
          "username": "john_smith",
          "idNumber": "9109056037088",
          "numbers": "0760487299",
          "email": "john@smith.com",
          "password": "john_123"
        }
      ]
    },
    "editContentHeaders": {
      "modules": [
        {
          "text": "Name",
          "value": "name"
        },
        {
          "text": "Code",
          "value": "code"
        },
        {
          "text": "Description",
          "value": "description"
        }
      ],
      "lecturers": [
        {
          "text": "First Name",
          "value": "firstname"
        },
        {
          "text": "Last Name",
          "value": "lastname"
        },
        {
          "text": "Username",
          "value": "username"
        },
        {
          "text": "Email",
          "value": "email"
        },
        {
          "text": "ID Number",
          "value": "idNumber"
        },
        {
          "text": "Last Seen",
          "value": "lastSeen"
        }
      ],
      "students": [
        {
          "text": "First Name",
          "value": "firstname"
        },
        {
          "text": "Last Name",
          "value": "lastname"
        },
        {
          "text": "Username",
          "value": "username"
        },
        {
          "text": "Email",
          "value": "email"
        },
        {
          "text": "ID Number",
          "value": "idNumber"
        },
        {
          "text": "Last Seen",
          "value": "lastSeen"
        }
      ],
      "admins": [
        {
          "text": "First Name",
          "value": "firstname"
        },
        {
          "text": "Last Name",
          "value": "lastname"
        },
        {
          "text": "Username",
          "value": "username"
        },
        {
          "text": "Email",
          "value": "email"
        },
        {
          "text": "ID Number",
          "value": "idNumber"
        },
        {
          "text": "Last Seen",
          "value": "lastSeen"
        }
      ]
    }
  }
  