
import Vue from "vue";

export default Vue.extend({
  name: "EditSchoolDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    currentSchool: {
      type: Object,
    },
  },
  data() {
    return {
      txtError: "",
      isLoading: false,
      secretPassword: "",
      correctCode: this.getMoment().format("dddd"),
      school: JSON.parse(JSON.stringify(this.currentSchool)),
    };
  },
  mounted() {
    this.done = false;
  },
  watch: {
    currentSchool(newVal, oldVal) {
      this.school = JSON.parse(JSON.stringify(newVal));
    },
    school(newVal, oldVal) {
      if (newVal && oldVal && oldVal.name != newVal.name) {
        this.school.logo = `https://ui-avatars.com/api/?name=${newVal.name}`;
      }
    },
  },
  methods: {
    closedDialog() {
      this.$emit("closedDialog");
    },
    updateTheme(args) {
      for (const colorKey in this.school.settings.colors) {
        this.$vuetify.theme.themes.light[colorKey] =
          this.school.settings.colors[colorKey];
      }
    },
    async updateSchoolDetails() {
      try {
        if (
          this.secretPassword.toLowerCase() != this.correctCode.toLowerCase()
        ) {
          throw new Error("You dont have permission to perform this action");
        }
        if (!this.school.name || this.school.name.length < 3) {
          throw new Error(
            "A school name of more than 2 charactors is required"
          );
        }
        if (!this.school.url || this.school.url.length < 3) {
          throw new Error("A school url of more than 2 charactors is required");
        }
        this.isLoading = true;
        await this.$api.updateSchoolDetails(this.school);

        this.$api.createNewUsageLog({
          schoolId: this.school._id,
          platform: "WEB",
          event: "UPDATED_SCHOOL",
          description: `Updated school details - ${this.school.name}`,
          metadata: {},
        });

        this.isLoading = false;
        this.closedDialog();
      } catch (err) {
        this.$dialog.fire("Unable to create school", err.message, "error");
        this.isLoading = false;
        this.txtError = err.message;
      }
    },
  },
});
