
import Vue from "vue";

export default Vue.extend({
  name: "AddStudent",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      txtError: "",
      isLoading: false,
      secretPassword: "",
      correctCode: this.getMoment().format("dddd"),
      school: {
        name: "",
        url: "",
        description: "",
        address: "",
        logo: "",
        settings: {
          colors: {
            primary: "#f96c5b",
            secondary: "#2d5afd",
            accent: "#8c9eff",
            error: "#b71c1c",
            info: "#00ff00",
            success: "#0000ff",
            warning: "#ffff00",
          },
        },
      },
    };
  },
  mounted() {
    this.done = false;
  },
  watch: {
    "school.name"(newVal, oldVal) {
      this.school.logo = `https://ui-avatars.com/api/?name=${newVal}`;
    },
  },
  methods: {
    closedDialog() {
      this.$emit("closedDialog");
    },
    updateTheme(args) {
      for (const colorKey in this.school.settings.colors) {
        this.$vuetify.theme.themes.light[colorKey] =
          this.school.settings.colors[colorKey];
      }
    },
    async createNewSchool() {
      try {
        if (
          this.secretPassword.toLowerCase() != this.correctCode.toLowerCase()
        ) {
          throw new Error("You dont have permission to perform this action");
        }
        if (!this.school.name || this.school.name.length < 3) {
          throw new Error(
            "A school name of more than 2 charactors is required"
          );
        }
        if (!this.school.url || this.school.url.length < 3) {
          throw new Error("A school url of more than 2 charactors is required");
        }
        this.isLoading = true;
        const schoolId = await this.$api.createNewSchool(this.school);

        this.$api.createNewUsageLog({
          schoolId: schoolId,
          platform: "WEB",
          event: "ADDED_SCHOOL",
          description: `Created - ${this.school.name}`,
          metadata: {},
        });

        this.isLoading = false;
        this.closedDialog();
      } catch (err) {
        this.$dialog.fire("Unable to create school", err.message, "error");
        this.isLoading = false;
        this.txtError = err.message;
      }
    },
  },
});
