
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    data: null as PropType<{
      title: String;
      value: String;
      icon?: String;
      iconColor?: String;
      description?: String;
    }>,
    isSelected: Boolean,
  },
  methods: {
    clicked() {
      this.$emit("click");
    },
  },
});
