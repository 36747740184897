
import Vue from "vue";
import Papa from "papaparse";

export default Vue.extend({
  name: "AddStudent",
  props: {
    school: Object,
    items: Array,
    headers: Array,
    sample: Array,
    title: String,
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sampleHeader() {
      if (!this.sample || this.sample.length == 0) {
        return [];
      }
      return Object.keys(this.sample[0]).map((h) => {
        const header = this.headers.find((v) => v.value == h);
        return {
          value: h,
          text: header ? header.text : h,
        };
      });
    },
  },
  watch: {
    showDialog(newVal, oldVal) {
      this.selectedItems = [];
      this.currentScreen = "SCREEN_VIEW";
      this.newItems = [];
      this.newItem = {};
      if (this.title == "lecturers" || this.title == "students") {
        this.getAllMyModules();
      }
    },
  },
  data() {
    return {
      selectedItems: [],
      newItems: [],
      newItem: {},
      currentScreen: "SCREEN_VIEW" as
        | "SCREEN_ADD_BULK"
        | "SCREEN_ADD_SINGLE"
        | "SCREEN_VIEW",
      txtSearch: "",
      isLoading: false,
      loadingCsv: false,
      allModules: [],
      isLoadingModules: false,
      selectedModules: [],
    };
  },
  mounted() {
    this.done = false;
    if (this.title == "lecturers" || this.title == "students") {
      this.getAllMyModules();
    }
  },
  methods: {
    closedDialog() {
      this.$emit("closedDialog");
    },
    async getAllMyModules() {
      try {
        this.isLoadingModules = true;
        this.allModules = [];
        this.selectedModules = [];
        if (!this.school || !this.school.url) {
          return this.$dialog.fire(
            "Unable to get modules",
            "A school is required to perform this action",
            "error"
          );
        }
        const modules = await this.$api.getAllMyModules({
          schoolUrl: this.school.url,
          filter: "name code",
        });

        this.allModules = modules.map((v) => ({
          ...v,
          title: `${v.name} (${v.code})`,
        }));
        console.log(this.allModules);
      } catch (err) {
        this.$dialog.fire("Unable to get modules", err.message, "error");
        this.txtError = err.message;
      }
      this.isLoadingModules = false;
    },
    async deleteSelectedItems() {
      const response = await this.$dialog.fire({
        title: `Are you sure you want to delete ${this.selectedItems.length} ${this.title}?`,
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.primary,
        cancelButtonColor: this.$vuetify.theme.themes.light.error,
        confirmButtonText: "Yes",
      });
      if (!response.value) {
        return;
      }
      const itemIds = this.selectedItems.map((v) => v._id);
      try {
        if (!this.school) {
          throw new Error("A school is required to perform this action");
        }
        if (itemIds.length == 0) {
          throw new Error(
            "Atleast one item must be selected to perform this action"
          );
        }
        this.isLoading = true;
        await this.$api.removeFromSchool(this.title, this.school._id, itemIds);

        this.$api.createNewUsageLog({
          schoolId: this.school._id,
          platform: "WEB",
          event: "REMOVED_FROM_SCHOOL",
          description: `${itemIds.length} ${this.title} removed from ${this.school.name}`,
          metadata: {
            itemIds,
          },
        });

        this.isLoading = false;
        this.closedDialog();
      } catch (err) {
        this.$dialog.fire(
          `Unable to delete ${this.selectedItems.length} ${this.title}`,
          err.message,
          "error"
        );
        this.isLoading = false;
      }
    },
    async addItem() {
      this.newItems.push(this.newItem);
      this.newItem = {};
    },
    async addItems() {
      const response = await this.$dialog.fire({
        title: `Are you sure you want to add ${this.newItems.length} ${this.title}?`,
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.primary,
        cancelButtonColor: this.$vuetify.theme.themes.light.error,
        confirmButtonText: "Yes",
      });
      console.log(this.selectedModules);
      if (!response.value) {
        return;
      }

      try {
        this.isLoading = true;
        if (!this.school) {
          throw new Error("A school is required to perform this action");
        }
        if (this.newItems.length == 0) {
          throw new Error(`No ${this.title} provided!`);
        }
        const allKeys = Object.keys(this.sample[0]);

        allKeys.forEach((key) => {
          if (
            !this.newItems.every((item) => item[key] && item[key].length > 1)
          ) {
            throw new Error(`All ${this.title} should have a ${key}`);
          }
        });
        const moduleIds =
          this.title == "lecturers" || this.title == "students"
            ? this.selectedModules
            : [];

        const itemIds = await this.$api.addToSchool(
          this.title,
          this.school._id,
          this.newItems,
          moduleIds
        );

        this.$api.createNewUsageLog({
          schoolId: this.school._id,
          platform: "WEB",
          event: "ADDED_TO_SCHOOL",
          description: `${this.newItems.length} ${this.title} added to ${this.school.name}`,
          metadata: {
            type: this.title,
            itemIds,
          },
        });

        this.isLoading = false;
        this.closedDialog();
      } catch (err) {
        this.$dialog.fire(
          `Unable to add ${this.newItems.length} ${this.title}`,
          err.message,
          "error"
        );
        this.isLoading = false;
      }
    },
    startUploadFile() {
      this.$refs.select_csv.click();
    },
    downloadSample() {
      const csv = Papa.unparse(this.sample);
      console.log(csv);

      this.exportToCSV(csv, this.title);
    },
    readFile(file) {
      return new Promise((resolve) => {
        try {
          const reader = new FileReader();
          reader.onload = function(e) {
            return resolve(e.target.result);
          };
          reader.readAsText(file);
        } catch (err) {
          return resolve(null);
        }
      });
    },
    async selectedFile(arg) {
      if (arg.target.files && arg.target.files.length >= 0) {
        const file = arg.target.files[0];
        if (/text\/.+/.test(file.type)) {
          this.loadingCsv = true;
          const csvString = await this.readFile(file);
          if (!csvString) {
            this.loadingCsv = false;
            this.$dialog.fire(
              "Unable to load selected file",
              "Your browser does not support file readers",
              "error"
            );
          }
          const result = Papa.parse(csvString, { header: true });
          if (result.data && result.data.length > 0) {
            console.log(result.data);

            this.newItems = result.data;
            this.$forceUpdate();
          }
          this.loadingCsv = false;
        } else {
          this.$dialog.fire(
            "Unable to load selected file",
            "We only support .csv files",
            "error"
          );
        }
      }
    },
  },
});
