<template>
  <div class="screen">
    <v-content v-if="Questionaire" class="mt-5 pt-5">
      <v-row class="mx-auto mt-5 pt-5">
        <v-col cols="12" v-if="isMemo" class="text-center">
          <h3 class="display-1">Memorandum creation</h3>
        </v-col>
        <v-col cols="12" sm="12" md="8" lg="8" xlg="8" class="mx-auto">
          <p
            v-if="currentPage < Questionaire.questions.length"
            class="text-center title"
          >
            Question <strong>{{ currentPage + 1 }}</strong> of
            <strong>{{ Questionaire.questions.length }}</strong>
          </p>
          <p
            class="text-center title"
            v-if="currentPage == Questionaire.questions.length"
          >
            Verify your answers and submit
          </p>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="8"
          class="mx-auto my-auto"
          v-for="(question, i) in Questionaire.questions"
          v-show="
            currentPage == i || currentPage == Questionaire.questions.length
          "
          :key="i"
        >
          <v-card :loading="isLoading" class="my-2 hoverable">
            <v-card-title class="display-1 px-4">
              {{ question.title }}
            </v-card-title>
            <RenderFTPFile
              v-if="question.file"
              height="auto"
              width="auto"
              :title="question.title"
              :ftpId="question.file"
            />
            <v-card-text class="px-10">
              <v-radio-group
                v-if="question.type == 'SINGLE_SELECT'"
                v-model="solutions[i]"
                column
              >
                <v-radio
                  v-for="(answer, j) in question.answers"
                  :key="j"
                  :disabled="timeUp"
                  :value="answer"
                  :label="answer"
                ></v-radio>
              </v-radio-group>
              <v-row class="pa-5" v-if="question.type == 'MULTIPLE_SELECT'">
                <v-col
                  cols="12"
                  class="ma-0 pa-0"
                  v-for="(answer, j) in question.answers"
                  :key="j"
                >
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="solutions[i]"
                    :disabled="timeUp"
                    multiple
                    :value="answer"
                    :label="answer"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-textarea
                v-if="question.type == 'FREE_TEXT'"
                :disabled="timeUp"
                outlined
                :placeholder="question.title"
                v-model="solutions[i]"
              >
              </v-textarea>

              <v-file-input
                v-if="question.type == 'UPLOAD_FILE'"
                label="File"
                @change="(file) => (solutions[i] = file)"
                placeholder="Select the file you want to upload"
                prepend-icon="mdi-paperclip"
                outlined
                counter
                accept="image/*, video/*, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf"
                show-size
              ></v-file-input>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          md="8"
          v-if="timeRemaining && totalTimeLimit"
          class="mx-auto my-auto"
          cols="12"
        >
          <v-progress-linear
            :value="(timeRemaining / totalTimeLimit) * 100"
          ></v-progress-linear>
          <p class="text-center subtitle-1">
            {{
              timeRemaining == null
                ? `No time limit`
                : timeRemaining == 0
                ? "You ran out of time"
                : `${timeRemainingString}`
            }}
          </p>
        </v-col>
        <v-col cols="12" sm="12" md="8" lg="8" xlg="8" class="mx-auto">
          <p class="center-align red-text" v-show="txtError.length > 2">
            {{ txtError }}
          </p>
        </v-col>
        <v-col cols="12" sm="12" md="8" lg="8" xlg="8" class="mx-auto">
          <v-row>
            <v-col cols="12" sm="12" md="4" class="mx-auto">
              <v-btn
                class="red float-right"
                large
                v-if="
                  currentPage > 0 && currentPage < Questionaire.questions.length
                "
                v-on:click="changePage(false)"
                >Previous</v-btn
              >
            </v-col>
            <v-col cols="12" sm="12" md="4" class="mx-auto">
              <v-btn
                class="secondary"
                large
                block
                v-if="currentPage < Questionaire.questions.length"
                v-on:click="changePage(true)"
                >Next</v-btn
              >
            </v-col>
            <v-col
              cols="12"
              sm="12"
              class="mx-auto"
              v-if="currentPage == Questionaire.questions.length && !isLoading"
            >
              <v-btn
                :loading="isLoading"
                color="secondary"
                large
                block
                v-on:click="SubmitQuiz()"
                >Submit</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-content>
  </div>
</template>

<script>
import swal from "sweetalert2";
import RenderFTPFile from "./Helpers/RenderFTPFile.vue";

export default {
  name: "Test",
  components: {
    RenderFTPFile,
  },
  data() {
    return {
      currentPage: 0,
      solutions: [],
      Questionaire: null,
      txtError: "",
      isLoading: false,
      timeRemaining: null,
      totalTimeLimit: null,
      timeRemainingString: "",
      timeUp: false,
      submitted: false,
      timer: null,
      isLoading: false,
      startTime: new Date(),
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timeUp = true;
  },
  mounted() {
    if (
      this.dbQuestionaire == null ||
      this.dbQuestionaire.questions == null ||
      this.dbQuestionaire.questions.length <= 0
    ) {
      this.$router.replace("/");
      return;
    }
    this.solutions = this.dbQuestionaire.questions.map((q) => null);

    if (this.dbQuestionaire.timeLimit) {
      this.timeRemaining = this.dbQuestionaire.timeLimit * 60;
    } else {
      this.timeRemaining = null;
    }

    this.Questionaire = this.dbQuestionaire;
    this.startTime = new Date();
    this.totalTimeLimit = this.timeRemaining;
    if (this.timeRemaining != null) {
      this.startTimer();
    }
  },
  props: ["dbQuestionaire", "isMemo"],
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.timeRemaining -= 1;
        if (this.timeRemaining > 60) {
          this.timeRemainingString =
            Math.round(this.timeRemaining / 60) + " minutes remaining";
        } else {
          this.timeRemainingString = this.timeRemaining + " seconds remaining";
        }

        if (
          this.timeRemaining == 5 * 60 ||
          this.timeRemaining == 15 * 60 ||
          this.timeRemaining == 30 * 60
        ) {
          this.$dialog.fire(
            "You have " + this.timeRemainingString,
            "Hurry up!",
            "info"
          );
        }

        if (this.timeRemaining <= 0) {
          clearInterval(this.timer);
          this.timeUp = true;
          this.currentPage = this.Questionaire.questions.length;
          this.SubmitQuiz();
          this.$dialog.fire(
            "Your time is up",
            "You can only submit your results",
            "error"
          );
        }
      }, 1000);
    },
    changePage(isForward) {
      this.txtError = "";
      isForward ? this.currentPage++ : this.currentPage--;
    },
    async SubmitQuiz() {
      this.txtError = "";
      let hasError = false;
      if (!this.timeUp) {
        this.solutions.map((s, i) => {
          if (s == null) {
            hasError = true;
            this.txtError = "Please provide an answer to this question";
            this.currentPage = i;
          }
        });
        if (hasError) {
          return;
        }

        const response = await this.$dialog.fire({
          title: `Are you sure?`,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: this.$vuetify.theme.themes.light.primary,
          cancelButtonColor: this.$vuetify.theme.themes.light.error,
          confirmButtonText: "Yes",
        });
        if (!response.value) {
          return;
        }
      }
      this.isLoading = true;

      const txtError = "";
      const answers = await Promise.all(
        this.Questionaire.questions.map(async (question, i) => {
          let answer = this.solutions[i];
          const { answers, file, type, title } = question;
          try {
            switch (type) {
              case "UPLOAD_FILE":
                const selectedFile = answer;
                if (selectedFile) {
                  const { filename: ftpId } = await this.$api.uploadFile(
                    selectedFile
                  );
                  answer = ftpId;
                }
                break;
              case "FREE_TEXT":
              case "MULTIPLE_SELECT":
              case "SINGLE_SELECT":
                break;
            }
          } catch (err) {
            txtError = `${question.title} - ${err.message}`;
          }
          return {
            answer,
            answers,
            file,
            type,
            question: title,
          };
        })
      );

      if (txtError) {
        this.isLoading = false;
        this.$dialog.fire(`Failed to process answer`, txtError, "error");
        return;
      }

      console.log("About to submit.....", answers);

      try {
        const { _id } = await this.$api.submitQuestionaire({
          answers,
          isMemo: this.isMemo,
          questionaireId: this.Questionaire._id,
        });

        const duration = this.getMoment().to(this.startTime, true);
        this.startTime = null;

        this.$api.createNewUsageLog({
          schoolId: this.$store.state.currentSchool?._id,
          platform: "WEB",
          event: "WROTE_TEST",
          description: `Wrote ${this.Questionaire?.title}`,
          metadata: {
            duration,
            questionaireId: this.Questionaire?._id,
            title: this.Questionaire?.title,
          },
        });

        this.$dialog.fire("Submitted!", {
          icon: "success",
        });
        this.$router.push({
          name: "TestMarks",
          params: {
            solutionId: _id,
          },
        });
      } catch (err) {
        this.$dialog.fire("Unable to submit", err.message, "error");
      }
      this.isLoading = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
