
import Vue from "vue";
import swal from "sweetalert2";

const axios = require("axios");

import AddStudent from "../Student/AddStudent";
import AddLecturer from "../Lecturer/AddLecturer";
import RenderFTPFile from "../Helpers/RenderFTPFile.vue";

// por downloafrom "../../plugins/download";

export default Vue.extend({
  name: "ModuleView",
  data() {
    return {
      selectedNote: null,
      selectedFile: null,
      selectedTabIndex: 0,
      selectedTab: "announcements" as
        | "announcements"
        | "questionaires"
        | "notes"
        | "students"
        | "lecturers",
      currentModule: null,
      tabContent: {},
      module: {
        students: [],
        lecturers: [],
      },
      announcement: {
        title: "",
        message: "",
        isToAll: false,
      },
      announcements: [],
      txtStudentSearch: "",
      txtLecturerSearch: "",
      txtError: "",
      addStudent: false,
      addLecturer: false,
      userType: "",
      addNotes: false,
      notes: {
        title: "",
        description: "",
        file: null,
        isDownloadable: true,
      },
      isLoading: false,
      isUploading: false,
      isAddingAnnouncements: false,
    };
  },
  components: {
    AddStudent,
    AddLecturer,
    RenderFTPFile,
  },
  computed: {
    filteredLecturers() {
      return this.module.lecturers.filter(
        (l) =>
          this.txtLecturerSearch.length < 1 ||
          JSON.stringify(l)
            .toLowerCase()
            .indexOf(this.txtLecturerSearch.toLowerCase()) >= 0
      );
    },
    filteredStudents() {
      return this.module.students.filter(
        (l) =>
          this.txtStudentSearch.length < 1 ||
          JSON.stringify(l)
            .toLowerCase()
            .indexOf(this.txtStudentSearch.toLowerCase()) >= 0
      );
    },
    filteredTests() {
      return this.module.questionaires;
    },
  },
  mounted() {
    this.userType = this.$store.state.user.type;
    if (this.moduleID == null) {
      this.$router.back();
    } else {
      this.refresh();
    }
  },
  watch: {
    selectedTab(newVal, oldVal) {
      this.reloadTabContent();
    },
    moduleID(newVal, oldVal) {
      this.refresh();
    },
  },
  props: ["moduleID"],
  methods: {
    goToSetTest() {
      this.$router.push({
        name: "SetTest",
        params: {
          moduleID: this.currentModule._id,
        },
      });
    },
    async reloadTabContent() {
      this.isLoading = true;
      try {
        if (!this.currentModule) {
          throw new Error("The module is not loaded yet!");
        }
        console.log({
          tab: this.selectedTab,
          current: this.currentModule[this.selectedTab],
        });
        this.tabContent[this.selectedTab] = await this.$api.lookupIds(
          this.selectedTab,
          this.currentModule[this.selectedTab],
          "-file.base64StringFile"
        );
      } catch (err) {
        this.$dialog.fire(
          "Unable to load " + this.selectedTab,
          err.message,
          "error"
        );
      }
      this.isLoading = false;
    },
    tabSelected(
      tabTitle:
        | "announcements"
        | "questionaires"
        | "notes"
        | "students"
        | "lecturers"
    ) {
      console.log(tabTitle);
      this.selectedTab = tabTitle;
    },
    showAnnouncement(announcement) {
      this.$api.createNewUsageLog({
        schoolId: this.$store.state.currentSchool?._id,
        platform: "WEB",
        event: "OPEN_ANNOUNCEMENT",
        description: `Opened ${announcement?.title}`,
        metadata: {
          announcementId: announcement?._id,
        },
      });

      swal.fire({
        title: announcement.title,
        text: announcement.message,
      });
    },
    loadAnnouncements() {
      axios
        .post(
          this.$store.state.settings.baseLink +
            "/n/announcements/get/for/" +
            this.$store.state.user.id,
          {
            userType: this.$store.state.user.type,
            moduleID: this.moduleID,
          }
        )
        .then((results) => {
          this.isLoading = false;
          this.announcements = results.data;
          console.log("Announcements....", results);
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.response != null && err.response.status == 512) {
            swal.fire("", err.response.data, "error");
          } else {
            swal.fire(err.message, "Try again later", "error");
          }
        });
    },
    async downloadNotes(notes: any) {
      this.isLoading = true;
      try {
        if (!notes.isDownloadable) {
          throw new Error("Use our mobile app to view it only");
        }
        this.selectedNote = notes;
        return;
      } catch (err) {
        this.$dialog.fire(
          "Unable to download " + notes.title,
          err.message,
          "error"
        );
      }
      this.isLoading = false;
    },
    handleNotesFileUpload(selectedFile) {
      this.selectedFile = selectedFile;
    },
    async uploadNotes() {
      this.txtError = "";
      if (!this.$store.state.currentUser) {
        this.txtError = "You are not logged in.";
      }

      if (!this.$store.state.currentSchool) {
        this.txtError = "A school is required to perform this action";
      }
      if (this.notes.title.length < 2) {
        this.txtError = "Please provide a valid title";
      }

      if (this.notes.description.length < 2) {
        this.txtError = "Please provide a valid description";
      }

      if (this.txtError.length > 2) {
        this.$dialog.fire("Unable to upload file", this.txtError, "error");
        return;
      }

      this.isUploading = true;
      try {
        const { filename: ftpId } = await this.$api.uploadFile(
          this.selectedFile
        );
        const lectureNotesId = await this.$api.saveNotes({
          title: this.notes.title,
          description: this.notes.description,
          isDownloadable: this.notes.isDownloadable,
          moduleId: this.moduleID,
          schoolUrl: this.$store.state.currentSchool.url,
          ftpId: ftpId,
        });

        this.$api.createNewUsageLog({
          schoolId: this.$store.state.currentSchool?._id,
          platform: "WEB",
          event: "ADDED_NOTES",
          description: `Uploaded ${this.notes.title}`,
          metadata: {
            ftpId: ftpId,
            lectureNotesId: lectureNotesId,
            moduleId: this.moduleID,
          },
        });

        this.$dialog.fire("Upload successfull", "", "success");
        this.addNotes = false;
        this.refresh();
      } catch (err) {
        this.$dialog.fire("Unable to upload file", err.message, "error");
      }
      this.isUploading = false;
    },
    AddedNewStudent(isAdded) {
      if (isAdded) {
        this.refresh();
      } else {
      }
    },
    AddedNewLecturer(isAdded) {
      if (isAdded) {
        this.refresh();
      } else {
      }
    },
    async refresh() {
      // TODO: Determine why this is here?
      // This might be handled by the new functionality of loading items later
      // this.loadAnnouncements();

      try {
        if (!this.$store.state.currentUser) {
          return this.$dialog.fire(
            "Unable to get module",
            "You have to be logged in to perform this action",
            "error"
          );
        }
        if (!this.$store.state.currentSchool) {
          return this.$dialog.fire(
            "Unable to get module",
            "A school must be selected",
            "error"
          );
        }
        this.isLoading = true;
        this.currentModule = await this.$api.getModuleById(
          this.$store.state.currentSchool.url,
          this.moduleID
        );

        this.$api.createNewUsageLog({
          schoolId: this.$store.state.currentSchool._id,
          platform: "WEB",
          event: "VISITED_MODULE",
          description: `Visited ${this.currentModule.name} (${this.currentModule.code})`,
          metadata: {
            moduleId: this.moduleID,
          },
        });

        await this.reloadTabContent();
        this.isLoading = false;
      } catch (err) {
        this.$dialog.fire("Unable to load module", err.message, "error");
        this.isLoading = false;
      }
    },
    DeepSearch() {
      alert("Deep for " + this.txtSearch);
    },
    getSolutionFor(questionaire) {
      this.$router.push(
        `/questionaire/solutions/${questionaire._id}/${questionaire.title}`
      );
    },
    SendAnnouncement() {
      this.isUploading = true;
      axios
        .post(
          this.$store.state.settings.baseLink +
            "/n/announcements/add/for/" +
            this.moduleID +
            "/by/" +
            this.$store.state.user.type +
            "/of/id/" +
            this.$store.state.user.id,
          {
            announcement: this.announcement,
          }
        )
        .then((results) => {
          this.isUploading = false;
          swal.fire("Success", "Announcement successfully sent.", "success");
        })
        .catch((err) => {
          this.isUploading = false;
          if (err.response != null && err.response.status == 512) {
            swal.fire(err.response.data, "Try again later", "error");
          } else {
            swal.fire(
              "Unable to load the questionaire",
              "Try again later",
              "error"
            );
          }
        });
    },
  },
});
