
export default {
  data() {
    return {
      modules: [],
      /// lecturer attendance
      tabSelectedAttendanceModule: null,
      createAttendanceIndex: 0,
      selectedAttendanceModule: null,
      selectedAttendanceModuleStudents: [],
      selectedAttendanceDuration: "5 minutes",
      selectedAttendanceDate: null,
      attendanceRegister: null,
      attendanceRegisterHistoryTimes: [],
      attendanceRegisterHistoryStudents: [],
      attendanceRegisterHistoryStudentsLoading: false,
      attendanceError: "",
    };
  },
  mounted() {
    this.getAllMyModules();
  },
  methods: {
    async getAllMyModules() {
      try {
        if (!this.$store.state.currentUser) {
          return this.$dialog.fire(
            "Unable to get modules",
            "You have to be logged in to perform this action",
            "error"
          );
        }
        if (
          !this.$store.state.currentSchool ||
          this.$store.state.currentSchool.role != "LECTURER"
        ) {
          return this.$dialog.fire(
            "Unable to get modules",
            "You have to be a lecturer to perform this action",
            "error"
          );
        }
        this.modules = await this.$api.getAllMyModules({
          schoolUrl: this.$store.state.currentSchool.url,
          filter: "name code",
        });
        console.log(this.modules);
      } catch (err) {
        this.$dialog.fire("Unable to get modules", err.message, "error");
        this.txtError = err.message;
      }
    },
    createAttendance() {
      this.attendanceError = "";
      if (!this.selectedAttendanceModule) {
        this.attendanceError = "Please select a module";
        return;
      }
      let duration = 310;
      switch (this.selectedAttendanceDuration) {
        case "Never":
          duration = 31538000;
          break;
        case "5 minutes":
          duration = 310;
          break;
        case "10 minutes":
          duration = 610;
          break;
        case "15 minutes":
          duration = 910;
          break;
        case "30 minutes":
          duration = 1810;
          break;
      }
      this.isLoading = true;
      // TODO : Fix this API CALL;
      alert("This feature is under construction");
      return;
      /*
      axios
        .post(
          this.$store.state.settings.baseLink +
            "/attendance/create/for/" +
            this.selectedAttendanceModule,
          {
            duration,
            lecturerId: this.$store.state.user.id,
          }
        )
        .then((results) => {
          this.isLoading = false;
          this.createAttendanceIndex = 1;
          this.attendanceRegister = results.data;
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.response != null && err.response.status == 512) {
            swal.fire(err.response.data, "error");
          } else {
            swal.fire(
              "Unable to create attendance register",
              "Try again later",
              "error"
            );
          }
        });
        */
    },
    changeAttendanceTab(e) {
      if (e == 1) {
        // Case is tab-home-history
        this.isLoading = true;
        axios
          .get(
            this.$store.state.settings.baseLink +
              "/attendance/get/times/for/" +
              this.selectedAttendanceModule
          )
          .then((results) => {
            this.isLoading = false;
            this.attendanceRegisterHistoryTimes = results.data;
          })
          .catch((err) => {
            this.isLoading = false;
            if (err.response != null && err.response.status == 512) {
              swal.fire(err.response.data, "error");
            } else {
              swal.fire(
                "Unable to get the attendance list",
                "Try again later",
                "error"
              );
            }
          });
      }
      console.log(e);
    },
    setStudentsForModule(moduleId) {
      this.selectedAttendanceModuleStudents = [];
      if (!moduleId) return;
      axios
        .get(
          this.$store.state.settings.baseLink +
            "/s/students/all/for/module/" +
            moduleId
        )
        .then((results) => {
          this.isLoading = false;
          this.selectedAttendanceModuleStudents = results.data.map((v) => {
            return {
              _id: v._id,
              username: v.username,
              lastname: v.lastname,
              firstname: v.firstname,
              selected: true,
            };
          });
          console.log("results", this.selectedAttendanceModuleStudents);
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.response != null && err.response.status == 512) {
            swal.fire(err.response.data, "", "error");
          } else {
            console.log(err);
            swal.fire(
              "Unable to get the attendance list",
              "Try again later",
              "error"
            );
          }
        });
    },
    selectAttendanceDate(time) {
      this.selectedAttendanceDate = time;
      this.attendanceRegisterHistoryStudentsLoading = true;
      axios
        .get(
          this.$store.state.settings.baseLink +
            "/attendance/get/for/" +
            this.selectedAttendanceDate._id
        )
        .then((results) => {
          this.attendanceRegisterHistoryStudentsLoading = false;
          this.attendanceRegisterHistoryStudents = results.data;
          console.log(
            "attendanceRegisterHistoryStudents",
            this.attendanceRegisterHistoryStudents
          );
        })
        .catch((err) => {
          this.attendanceRegisterHistoryStudentsLoading = false;
          if (err.response != null && err.response.status == 512) {
            swal.fire(err.response.data, "error");
          } else {
            swal.fire(
              "Unable to get the attendance list",
              "Try again later",
              "error"
            );
          }
        });
    },
    submitBulkAttendance() {
      console.log("Here we go!");
      const students = this.selectedAttendanceModuleStudents
        ? this.selectedAttendanceModuleStudents
            .filter((v) => v && v.selected)
            .map((v) => v._id)
        : null;
      const code = this.attendanceRegister
        ? this.attendanceRegister.code
        : null;
      if (!students || !code) {
        swal.fire(
          "Unable to sign register",
          "make sure you selected students",
          "error"
        );
        return;
      }
      console.log("selected students", students);
      console.log("selected code", code);
      this.isLoading = true;
      axios
        .post(
          this.$store.state.settings.baseLink +
            "/attendance/sign/bulk/students",
          {
            students,
            code,
          }
        )
        .then((results) => {
          this.isLoading = false;
          swal.fire(results.data, "", "success");
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.response != null && err.response.status == 512) {
            swal.fire(err.response.data, "", "error");
          } else {
            console.log(err);
            swal.fire(
              "Unable to get the attendance list",
              "Try again later",
              "error"
            );
          }
        });
    },
  },
};
