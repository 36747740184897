
import Vue from "vue";
import RenderFTPFile from "../Helpers/RenderFTPFile.vue";

export default Vue.extend({
  name: "QuestionaireSolutionView",
  components: {
    RenderFTPFile,
  },
  data() {
    return {
      isLoading: false,
      solution: this.currentSolution,
    };
  },
  props: {
    currentSolution: Object,
  },
  watch: {
    currentSolution(newVal, oldVal) {
      this.solution = newVal;
    },
  },
  methods: {
    closedDialog(mustRefresh = false) {
      this.$emit("closedDialog", mustRefresh);
    },
    async submitMarkedSolution() {
      const response = await this.$dialog.fire({
        title: `Are you sure?`,
        text: "Confirm if this must be submitted",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.primary,
        cancelButtonColor: this.$vuetify.theme.themes.light.error,
        confirmButtonText: "Yes",
      });
      if (!response.value) {
        return;
      }

      if (!this.$store.state.currentUser) {
        return this.$dialog.fire(
          "Unable to submit questionaire",
          "You are not logged in",
          "error"
        );
      }

      if (!this.$store.state.currentSchool) {
        return this.$dialog.fire(
          "Unable to submit questionaire",
          "A school is required to perform this action",
          "error"
        );
      }

      if (!this.isCurrentLecturer && !this.isCurrentAdmin) {
        return this.$dialog.fire(
          "Unable to submit questionaire",
          "You do not have permission to proceed.",
          "error"
        );
      }

      this.isLoading = true;

      try {
        await this.$api.submitMarkedSolution({
          ...this.solution,
        });
        this.closedDialog(true);
      } catch (err) {
        this.$dialog.fire(
          "Unable to submit questionaire",
          err.message,
          "error"
        );
      }
      this.isLoading = false;
    },
  },
});
