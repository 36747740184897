
import Vue from "vue";
import swal from "sweetalert2";
import UploadProgress from "./components/Modals/UploadProgress.vue";

export default Vue.extend({
  name: "App",
  components: {
    UploadProgress,
  },
  data() {
    return {
      isRefreshing: false,
      navItems: [
        {
          name: "Home",
          icon: "mdi-home",
          link: "/",
          auth: ["STUDENT", "LECTURER", "ADMIN"],
        },
        {
          name: "School Management",
          icon: "mdi-table-cog",
          link: "/school/list",
          auth: ["ADMIN"],
        },
        {
          name: "Send Announcement",
          icon: "mdi-bell",
          link: "/announcement/add",
          auth: ["LECTURER", "ADMIN"],
        },
        {
          name: "Modules",
          icon: "mdi-bookshelf",
          link: "/module/list",
          auth: ["ADMIN", "LECTURER", "STUDENT"],
        },
        {
          name: "My Network",
          icon: "mdi-account-network",
          link: "/my-network",
          auth: ["ADMIN", "LECTURER", "STUDENT"],
        },
      ],
    };
  },
  methods: {
    refresh() {
      this.getMyProfileForSchool();
    },
    confirmLogout() {
      swal
        .fire({
          title: "Are you sure?",
          text: "Your current session will be destroyed!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Log out",
          cancelButtonText: "Cancel",
        })
        .then((result) => {
          if (result.value) {
            this.$api.logout();
            this.$router.replace("/login");
          }
        });
    },
    async getMyProfileForSchool() {
      try {
        if (!this.$store.state.currentSchool) {
          return;
        }
        this.isRefreshing = true;
        const { user, school } = await this.$api.getMyProfileForSchool(
          this.$store.state.currentSchool.url
        );
        this.$store.commit("setCurrentUser", user);
        this.$store.commit("setCurrentSchool", school);
        if (school?.settings?.colors) {
          this.$vuetify.theme.themes.light = school?.settings?.colors;
        }
      } catch (err) {
        this.$dialog.fire("Unable to your profile", err.message, "error");
      }
      this.isRefreshing = false;
    },
  },
});
