
import Vue from "vue";

export default Vue.extend({
  name: "CreateMyNetwork",
  data() {
    return {
      txtError: "",
      isLoading: false,
      newPost: {
        title: "",
        description: "",
        moduleIds: [],
        ftp: {
          files: [],
          attachments: [],
        },
        isToAllSchools: false,
      },
    };
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    allModules: {
      type: Array,
      default: [],
    },
  },
  methods: {
    closedDialog(mustRefresh = false) {
      this.$emit("closedDialog", mustRefresh);
    },
    showUploadFile() {
      this.$refs.upload_file.click();
    },
    handleFileUpload(args) {
      this.newPost.ftp.files.push(...args.target.files);
    },
    showUploadAttachment() {
      this.$refs.upload_attachment.click();
    },
    handleAttachmentUpload(args) {
      this.newPost.ftp.attachments.push(...args.target.files);
    },
    async submit() {
      const response = await this.$dialog.fire({
        title: `Are you sure?`,
        text: "Confirm if this must be posted",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.primary,
        cancelButtonColor: this.$vuetify.theme.themes.light.error,
        confirmButtonText: "Yes",
      });
      if (!response.value) {
        return;
      }

      if (!this.$store.state.currentUser) {
        return this.$dialog.fire(
          "Unable to post",
          "You are not logged in",
          "error"
        );
      }

      if (!this.$store.state.currentSchool) {
        return this.$dialog.fire(
          "Unable to post",
          "A school is required to perform this action",
          "error"
        );
      }

      if (this.newPost.title.length < 3) {
        return this.$dialog.fire(
          "Unable to post",
          "Your title is too short",
          "error"
        );
      }

      if (this.newPost.description.length < 3) {
        return this.$dialog.fire(
          "Unable to post",
          "Your message is too short",
          "error"
        );
      }

      if (this.newPost.moduleIds.length == 0) {
        return this.$dialog.fire(
          "Unable to post",
          "Atleast one module must be selected",
          "error"
        );
      }

      this.isLoading = true;
      const ftpFiles = [];
      const ftpAttachments = [];
      try {
        const promisedFiles = this.newPost.ftp.files.map(async (file) => {
          const {
            filename: ftpId,
            originalname,
            mimetype: contentType,
          } = await this.$api.uploadFile(file);
          return {
            ftpId,
            originalname,
            contentType,
          };
        });

        const promisedAttachments = this.newPost.ftp.attachments.map(
          async (file) => {
            const {
              filename: ftpId,
              originalname,
              mimetype: contentType,
            } = await this.$api.uploadFile(file);
            return {
              ftpId,
              originalname,
              contentType,
            };
          }
        );

        ftpFiles.push(...(await Promise.all(promisedFiles)));
        ftpAttachments.push(...(await Promise.all(promisedAttachments)));
      } catch (err) {
        this.isLoading = false;
        console.log({ err });
        return this.$dialog.fire({
          title: "Unable to upload files/attachments",
          text: err.message,
          icon: "error",
        });
      }

      try {
        await this.$api.createNewMyNetwork({
          schoolId: this.$store.state.currentSchool?._id,
          userRole: this.$store.state.currentSchool?.role,
          title: this.newPost.title,
          modules: this.newPost.moduleIds,
          description: this.newPost.description,
          ftp: {
            files: ftpFiles,
            attachments: ftpAttachments,
          },
          isToAllSchools: this.newPost.isToAllSchools,
        });
      } catch (err) {
        this.isLoading = false;
        console.log({ err });
        return this.$dialog.fire({
          title: "Unable to upload files/attachments",
          text: err.message,
          icon: "error",
        });
      }
      this.$dialog.fire("Submitted successfully", "", "success");
      this.isLoading = false;
      this.closedDialog(true);
    },
  },
});
