
import Vue from "vue";
import CardWidget from "./Widgets/CardWidget.vue";

export default Vue.extend({
  name: "UsageReport",
  mounted() {
    this.reload();
  },
  components: {
    CardWidget,
  },
  data() {
    return {
      isLoading: false,
      selectedSchool: {
        name: "",
        url: "",
        description: "",
        address: "",
        logo: null,
        backgroundImg: null,
      },
      txtSearch: "",
      schools: [],
      detailedEvents: [],
      usageReports: [
        {
          key: "LOGIN",
          widget: "CardWidget",
          grid: {
            cols: 12,
            sm: 6,
            md: 3,
            lg: 3,
          },
          data: {
            title: `Logins`,
            value: "",
            icon: "mdi-account-check-outline",
            iconColor: "primary",
          },
        },
        {
          key: "DOWNLOADED_CONTENT",
          widget: "CardWidget",
          grid: {
            cols: 12,
            sm: 6,
            md: 3,
            lg: 3,
          },
          data: {
            title: `Downloaded content`,
            value: "",
            icon: "mdi-file-download-outline",
            iconColor: "secondary",
          },
        },
        {
          key: "WROTE_TEST",
          widget: "CardWidget",
          grid: {
            cols: 12,
            sm: 6,
            md: 3,
            lg: 3,
          },
          data: {
            title: `Submitted assessments`,
            value: "",
            icon: "mdi-book-open-page-variant",
            iconColor: "primary",
          },
        },
        {
          key: "VISITED_MODULE",
          widget: "CardWidget",
          grid: {
            cols: 12,
            sm: 6,
            md: 3,
            lg: 3,
          },
          data: {
            title: `Module Visits`,
            value: "",
            icon: "mdi-human-male-board",
            iconColor: "secondary",
          },
        },
      ],
      selectedDates: [this.getMoment().format("YYYY-MM-DD")],
      selectedSchools: [],
      selectedEvents: [],
    };
  },
  computed: {
    detailedEventHeaders() {
      if (this.detailedEvents.length == 0) {
        return [];
      }

      return Object.keys(this.detailedEvents[0]).map((v) => ({
        value: v,
        text: v,
      }));
    },
  },
  watch: {
    selectedDates(newVal, oldVal) {
      this.getUsageReport();
    },
    selectedSchools(newVal, oldVal) {
      this.getUsageReport();
    },
  },
  methods: {
    reload() {
      this.getSchoolsAsAdmin();
    },
    async getSchoolsAsAdmin() {
      try {
        this.isLoading = true;
        this.schools = await this.$api.getSchoolsAsAdmin();
        this.selectedSchools = this.schools.map((v) => v._id);
        this.getUsageReport();
      } catch (err) {
        this.$dialog.fire("Unable to load schools", err.message, "error");
      }
      this.isLoading = false;
    },
    async getUsageReport() {
      if (this.selectedSchools.length == 0) {
        return this.$dialog.fire(
          "Unable to load report",
          "Select atleast one school",
          "error"
        );
      }
      if (this.selectedDates.length == 0) {
        return this.$dialog.fire(
          "Unable to load report",
          "Select atleast one date",
          "error"
        );
      }

      this.isLoading = true;
      try {
        this.isLoading = true;
        const usageReports = await this.$api.getUsageReport({
          schoolIds: this.selectedSchools,
          dates: this.selectedDates.map((d) => ({
            start: this.getMoment(`${d}T00:00:00+02:00`).toISOString(true),
            end: this.getMoment(`${d}T23:59:59+02:00`).toISOString(true),
          })),
        });
        const { stats } = usageReports;
        this.usageReports.forEach((usage) => {
          usage.data.value = stats[usage.key] || 0;
        });
        this.getFullUsageReport();
      } catch (err) {
        this.$dialog.fire("Unable to load report", err.message, "error");
      }
      this.isLoading = false;
    },
    async getFullUsageReport() {
      this.isLoading = true;
      try {
        this.isLoading = true;
        const fullUsageReport = await this.$api.getFullUsageReport({
          schoolIds: this.selectedSchools,
          dates: this.selectedDates.map((d) => ({
            start: this.getMoment(`${d}T00:00:00+02:00`).toISOString(true),
            end: this.getMoment(`${d}T23:59:59+02:00`).toISOString(true),
          })),
          events: this.selectedEvents,
        });

        this.detailedEvents = fullUsageReport.map((usage) => ({
          date: this.getMoment(usage.date).format("HH:mm - Do MMM YYYY"),
          user: `${usage.user?.firstname} ${usage.user?.lastname}`,
          school: usage.school?.name || "",
          title: usage.description,
        }));
      } catch (err) {
        this.$dialog.fire("Unable to load report", err.message, "error");
      }
      this.isLoading = false;
    },
    async selectedUsage(usage) {
      const index = this.selectedEvents.indexOf(usage.key);
      if (index >= 0) {
        this.selectedEvents.splice(index, 1);
      } else {
        this.selectedEvents.push(usage.key);
      }

      this.getFullUsageReport();
    },
  },
});
