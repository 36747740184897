<template>
  <div class="hello">
    <v-row>
      <v-col cols="10"> </v-col>
      <v-col cols="2">
        <v-btn right v-on:click="$router.back()" class="primary justify-end">
          <v-icon>mdi-keyboard-backspace</v-icon>
          <span class="px-2">Back</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row fill-height class="mt-5 my-auto">
      <v-col cols="12" sm="12" md="8" lg="6" xlg="6" class="mt-5 mx-auto">
        <v-text-field
          :readonly="currentPage > 0"
          label="Title"
          outlined
          v-model="title"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="currentPage == 0" class="mx-2">
      <v-col
        cols="12"
        sm="12"
        md="8"
        offset-md="2"
        lg="6"
        offset-lg="3"
        xl="6"
        offset-xl="3"
        class="mx-auto"
      >
        <v-card v-for="(question, i) in questions" :key="i" class="ma-2">
          <v-list-item>
            <v-list-item-avatar color="grey"></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ question.title }}</v-list-item-title>
              <RenderFTPFile
                v-if="question.file"
                height="auto"
                width="auto"
                :title="question.title"
                :ftpId="question.file"
              />
              <v-list-item-subtitle
                v-show="
                  question.type == 'SINGLE_SELECT' ||
                  question.type == 'MULTIPLE_SELECT'
                "
                >{{ question.answers.length }} answers</v-list-item-subtitle
              >
              <v-list-item-subtitle v-show="question.type == 'FREE_TEXT'"
                >Free text</v-list-item-subtitle
              >
              <v-list-item-subtitle v-show="question.type == 'UPLOAD_FILE'"
                >Upload file</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-action>
              <v-btn v-on:click="DeleteQuestion(question)" icon>
                <v-icon class="text-peach">mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!addingQuestion && currentPage == 0" class="mx-2">
      <v-col cols="12" sm="12" md="6" lg="4" xlg="4" class="mx-auto">
        <v-btn
          :disabled="!title"
          block
          outlined
          color="secondary"
          @click="AddQuestion()"
          >Add Question</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="mx-2" v-if="addingQuestion && currentPage == 0">
      <v-col cols="12" sm="12" md="10" lg="8" xlg="8" class="mx-auto pa-2">
        <v-card class="pa-3">
          <v-btn
            icon
            class="float-right"
            color="error"
            v-on:click="addingQuestion = false"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-row class="row">
            <v-col cols="grow">
              <v-text-field
                outlined
                label="Question"
                v-model="questionaire.title"
                placeholder="Type the question here..."
                type="text"
                class="validate"
              ></v-text-field>
            </v-col>
            <v-col cols="auto" class="my-auto">
              <v-select
                outlined
                label="Type"
                dense
                :items="questionTypes"
                v-model="questionaire.type"
                :hint="questionaireTypeHint"
                persistent-hint
              ></v-select>
            </v-col>

            <v-col cols="12" class="py-5">
              <v-row>
                <v-col cols="12" sm="12" md="6" class="mx-auto">
                  <v-file-input
                    @change="(file) => (questionaire.selectedFile = file)"
                    placeholder="Select a file that must be displayed here"
                    prepend-icon="mdi-paperclip"
                    outlined
                    counter
                    dense
                    :loading="isUploadingFile"
                    accept="image/*, video/*, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf"
                    show-size
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-if="
              questionaire.type == 'SINGLE_SELECT' ||
              questionaire.type == 'MULTIPLE_SELECT'
            "
          >
            <v-col
              v-for="(answer, i) in answers"
              :key="i"
              cols="12"
              sm="12"
              md="8"
              class="mx-auto"
            >
              <v-text-field
                outlined
                :label="`Option ${i + 1}`"
                v-model="questionaire.answers[i]"
                :id="'answer-' + i"
                type="text"
                dense
                class="validate"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xlg="6" class="mx-auto">
              <v-btn
                outlined
                icon
                color="secondary"
                class="btn-flat float-right"
                @click="AddAnswer()"
              >
                <v-icon x-large>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xlg="6" class="mx-auto">
              <v-btn
                icon
                color="primary"
                class="btn-flat"
                @click="RemoveAnswer()"
              >
                <v-icon x-large>mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              :loading="isUploadingFile"
              block
              color="secondary"
              x-large
              v-on:click="SaveQuestion()"
              >Save Question</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="!addingQuestion && questions.length > 0 && currentPage == 0"
      class="mx-2"
    >
      <v-col cols="12" sm="12" md="10" lg="8" xlg="8" class="mx-auto pa-2">
        <v-card class="pa-5">
          <v-row>
            <v-col cols="12" sm="12" md="4" class="mx-auto text-center">
              <v-select
                :items="timeLimits"
                v-model="questionaire.timeLimit"
                label="Time limit"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="mx-auto text-center">
              <v-select
                :items="attemptLimits"
                v-model="questionaire.attemptLimit"
                label="Number of attempts"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="mx-auto text-center">
              <v-select
                :items="markingMechanismOptions"
                v-model="questionaire.markingMechanism"
                label="Marking mechanism"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6" class="mx-auto">
              <v-btn
                block
                color="secondary"
                x-large
                :loading="isLoading"
                @click="createQuestionaire()"
                >Create</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="currentPage == 1 && dbQuestionaire != null">
      <TakeTest :dbQuestionaire="dbQuestionaire" :isMemo="true" />
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import TakeTest from "./TakeTest.vue";
import RenderFTPFile from "./Helpers/RenderFTPFile.vue";

export default {
  name: "SetTest",
  components: {
    TakeTest,
    RenderFTPFile,
  },
  data() {
    return {
      hasExpiryDatetime: false,
      currentPage: 0,
      title: "",
      questionaire: {
        title: "",
        type: "SINGLE_SELECT",
        attachments: [],
        answers: [],
        expiryDate: null,
        expiryTime: null,
        timeLimit: null,
        attemptLimit: null,
        markingMechanism: "AUTOMATED",
        selectedFile: null,
      },
      addingQuestion: false,
      answers: [
        {
          value: "Jacob Zuma",
        },
      ],
      dbQuestionaire: null,
      questions: [],
      timeLimits: [
        { text: "No limit", value: null },
        { text: "10 minutes", value: 10 },
        { text: "30 minutes", value: 30 },
        { text: "45 minutes", value: 45 },
        { text: "1 hour", value: 60 },
        { text: "1 hour 30 minutes", value: 90 },
        { text: "2 hours", value: 120 },
      ],
      attemptLimits: [
        { text: "No Limit", value: null },
        { text: "Once", value: 1 },
        { text: "Twice", value: 2 },
        { text: "Three times", value: 3 },
        { text: "Five times", value: 5 },
      ],
      questionTypes: [
        { text: "Pick one correct answer", value: "SINGLE_SELECT" },
        { text: "Pick multiple correct answers", value: "MULTIPLE_SELECT" },
        { text: "Type an answer", value: "FREE_TEXT" },
        { text: "Upload file", value: "UPLOAD_FILE" },
      ],
      markingMechanismOptions: [
        { text: "Automated", value: "AUTOMATED" },
        { text: "Manual", value: "MANUAL" },
      ],
      isLoading: false,
      isUploadingFile: false,
    };
  },
  mounted() {
    if (this.moduleID == null) {
      this.$router.back();
    } else {
      this.refresh();
    }
  },
  computed: {
    questionaireTypeHint() {
      switch (this.questionaire.type) {
        case "SINGLE_SELECT":
          return "The user will pick one correct answer";
        case "MULTIPLE_SELECT":
          return "The user will pick multiple correct answers";
        case "FREE_TEXT":
          return "The user will type an answer in the text field";
        case "UPLOAD_FILE":
          return "The user will be prompted to upload a file.";
      }
    },
  },
  props: ["moduleID"],
  methods: {
    refresh() {},
    AddQuestion() {
      this.addingQuestion = true;
    },
    AddAnswer() {
      this.answers.push({});
    },
    RemoveAnswer() {
      this.answers.pop();
    },
    async DeleteQuestion(question) {
      const response = await this.$dialog.fire({
        title: `Are you sure?`,
        text: "Once deleted, you will not be able to recover this question",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.primary,
        cancelButtonColor: this.$vuetify.theme.themes.light.error,
        confirmButtonText: "Yes",
      });
      if (!response.value) {
        return;
      }

      const index = this.questions.indexOf(question);
      this.questions.splice(index, 1);
    },
    async SaveQuestion() {
      if (this.questionaire.title.length < 2) {
        swal.fire(
          "Incomplete question",
          "Please provide a valid question",
          "error"
        );
        return;
      }

      switch (this.questionaire.type) {
        case "FREE_TEXT":
        case "UPLOAD_FILE":
          // We clear the answers for this cases
          this.questionaire.answers = [];
          break;
        case "MULTIPLE_SELECT":
        case "SINGLE_SELECT":
          if (this.questionaire.answers.length < 1) {
            this.$dialog.fire(
              "Incomplete question",
              "Please provide atleast one answer to the question",
              "error"
            );
            return;
          }
          break;
      }

      let file = null;
      if (this.questionaire.selectedFile) {
        this.isUploadingFile = true;
        try {
          const { filename } = await this.$api.uploadFile(
            this.questionaire.selectedFile
          );
          file = filename;
          this.questionaire.selectedFile = null;
          this.isUploadingFile = false;
        } catch (err) {
          this.isUploadingFile = false;
          this.$dialog.fire("Unable to upload file", err.message, "error");
          return;
        }
      }

      this.questions.push({
        id:
          this.questionaire.title +
          "-" +
          Date.now() +
          "-" +
          this.questions.length,
        type: this.questionaire.type,
        file: file,
        title: this.questionaire.title,
        answers: this.questionaire.answers,
        expiryDate: this.hasExpiryDatetime
          ? this.questionaire.expiryDate
          : null,
        expiryTime: this.hasExpiryDatetime
          ? this.questionaire.expiryTime
          : null,
        timeLimit: this.questionaire.timeLimit,
      });

      this.questionaire.title = "";
      this.questionaire.answers = [];
      this.addingQuestion = false;
    },
    async createQuestionaire() {
      const response = await this.$dialog.fire({
        title: `Are you sure?`,
        text: "Confirm if the questionaire must be created",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.primary,
        cancelButtonColor: this.$vuetify.theme.themes.light.error,
        confirmButtonText: "Yes",
      });
      if (!response.value) {
        return;
      }

      if (!this.$store.state.currentUser) {
        return this.$dialog.fire(
          "Unable to submit questionaire",
          "You are not logged in",
          "error"
        );
      }

      if (!this.$store.state.currentSchool) {
        return this.$dialog.fire(
          "Unable to submit questionaire",
          "A school is required to perform this action",
          "error"
        );
      }

      if (!this.isCurrentLecturer && !this.isCurrentAdmin) {
        return this.$dialog.fire(
          "Unable to submit questionaire",
          "You do not have permission to proceed.",
          "error"
        );
      }

      if (this.title.length < 3) {
        return this.$dialog.fire(
          "Incomplete questionaire",
          "Please provide a title for your questionaire",
          "error"
        );
      }

      if (
        this.hasTimeLimit &&
        (this.questionaire.timeLimit == null ||
          this.questionaire.timeLimit.length < 2)
      ) {
        return this.$dialog.fire(
          "Invalid time limit",
          "Please provide a valid time limit",
          "error"
        );
      }
      this.isLoading = true;

      try {
        const questionaire = await this.$api.createQuestionaire({
          title: this.title,
          questions: this.questions,
          timeLimit: this.questionaire.timeLimit,
          schoolUrl: this.$store.state.currentSchool.url,
          moduleId: this.moduleID,
        });

        this.$api.createNewUsageLog({
          schoolId: this.$store.state.currentSchool?._id,
          platform: "WEB",
          event: "ADDED_TEST",
          description: `Created a test - ${this.title} with ${this.questions.length} questions`,
          metadata: {
            questionaireId: questionaire?._id,
            moduleId: this.moduleID,
          },
        });

        this.dbQuestionaire = questionaire;
        this.currentPage = 1;
      } catch (err) {
        this.$dialog.fire(
          "Unable to submit questionaire",
          err.message,
          "error"
        );
      }
      this.isLoading = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.p-10 {
  padding: 10px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.pointer {
  cursor: pointer;
}

.selectedTag {
  background-color: #42b983 !important;
}
</style>
