import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import user from "./modules/user";
import settings from "./modules/settings";
import collections from "./modules/collections";

const vuexLocal = new VuexPersistence({
  key: "coportal_store",
  storage: window.localStorage,
});
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  state: {
    currentSchool: null as any,
    currentUser: null as any,
    currentToken: null as any,
    currentUploads: [] as {title:string,progress:number}[]
  },
  mutations: {
    setCurrentSchoolUrl(state, currentSchoolUrl) {
      state.currentSchool = {
        url: currentSchoolUrl,
      };
    },
    setAuthToken(state, currentToken) {
      state.currentToken = currentToken;
    },
    setCurrentUser(state, currentUser) {
      state.currentUser = currentUser;
    },
    setCurrentSchool(state, currentSchool) {
      state.currentSchool = currentSchool;
    },
    setCurrentUploadProgress(state, currentUpload) {
      const index = state.currentUploads.findIndex(v => v.title == currentUpload.title)
      if(index >= 0){
          state.currentUploads[index].progress = currentUpload.progress;
      } else{
        state.currentUploads.unshift(currentUpload)
      }
    },
    clearCurrentUploads(state){
      state.currentUploads = []
    }
  },
  modules: {
    user,
    settings,
    collections,
  },
  strict: debug,
  plugins: [vuexLocal.plugin],
});
Vue.prototype.$store = store;
export default store;
