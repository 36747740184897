<template>
  <div class="screen">
    <v-row>
      <v-col cols="10"> </v-col>
      <v-col cols="2">
        <v-btn right v-on:click="$router.back()" class="primary justify-end">
          <v-icon>mdi-keyboard-backspace</v-icon>
          <span class="px-2">Back</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="8" lg="6" class="mx-auto">
        <v-btn outlined color="primary" large block v-on:click="logout()">
          <span class="px-5">Done</span>
          <v-icon>mdi-thumb-up-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row style="height:80vh">
      <v-col cols="12" sm="12" md="8" lg="6" class="mx-auto my-auto">
        <add-student v-on:submitted="AddedNewStudent"></add-student>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import swal from "sweetalert2";
import * as moment from "moment";

const axios = require("axios");

import AddStudent from "../Student/AddStudent";

export default {
  name: "StidentList",
  data() {
    return {
      studentModule: {
        _id: null,
        username: "",
        modules: [null],
        oldModules: []
      },
      txtSearch: "",
      txtError: "",
      student: {
        firstname: "",
        lastname: "",
        username: "",
        password: "",
        confirmPassword: "",
        modules: [null],
        idNumber: "",
        gender: "",
        dob: "",
        isSouthAfrican: false
      },
      addingStudents: false,
      students: [],
      modules: [],
      selectedStudent: null,
      showEditProfile: false,
      isFullscreen: null
    };
  },
  watch: {
    "student.idNumber": function(newVal, oldVal) {
      if (newVal.length >= 6) {
        this.student.dob = new Date(
          newVal.substring(0, 2),
          newVal.substring(2, 4) - 1,
          newVal.substring(4, 6)
        );

        if (newVal.length >= 10) {
          var genderCode = newVal.substring(6, 10);
          this.student.gender = parseInt(genderCode) < 5000 ? "Female" : "Male";

          if (newVal.length == 13) {
            this.student.isSouthAfrican =
              parseInt(newVal.substring(10, 11)) == 0;
          }
        }
      }
    }
  },
  props: ["studentIDs"],
  components: { AddStudent },
  computed: {
    filteredStudents() {
      return this.students.filter(
        l =>
          this.txtSearch.length < 1 ||
          JSON.stringify(l)
            .toLowerCase()
            .indexOf(this.txtSearch.toLowerCase()) >= 0
      );
    }
  },
  mounted() {},
  methods: {
    AddedNewStudent(isAdded) {
      if (isAdded) {
        this.Reload();
      } else {
      }
    }
  }
};
</script>

<style scoped>
</style>

