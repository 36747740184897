
import Vue from "vue";
import AddSchoolDialog from "./AddSchoolDialog.vue";
import EditSchoolDialog from "./EditSchoolDialog.vue";
import SchoolCollectionDialog from "./SchoolCollectionDialog.vue";
import { samples } from "../../plugins/schoolComponents";

export default Vue.extend({
  name: "SchoolList",
  mounted() {
    this.reload();
  },
  components: {
    AddSchoolDialog,
    EditSchoolDialog,
    SchoolCollectionDialog,
  },
  data() {
    return {
      isLoading: false,
      addNewSchool: false,
      editNewSchool: false,
      editContent: null,
      currentSchool: null,
      currentEditSchool: null,
      selectedSchool: {
        name: "",
        url: "",
        description: "",
        address: "",
        logo: null,
        backgroundImg: null,
      },
      txtSearch: "",
      schools: [],
      headers: [
        {
          text: "",
          value: "logo",
          class: "grow",
          width: "grow",
        },
        {
          text: "Name",
          value: "name",
          sortable: true,
          width: "grow",
        },
        {
          text: "Url",
          value: "url",
          sortable: true,
          width: "Grow",
        },
        {
          text: "Management",
          value: "management",
          sortable: true,
          width: "Grow",
        },
        {
          text: "Created",
          value: "date",
          sortable: true,
        },
        {
          text: "Access",
          value: "access",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: true,
        },
      ],
      editContentSample: samples.editContentSample,
      editContentHeaders: samples.editContentHeaders,
    };
  },
  methods: {
    closedDialog() {
      this.addNewSchool = false;
      this.editNewSchool = false;
      this.editContent = null;
      this.currentEditSchool = null;
      this.reload();
    },
    reload() {
      this.getSchoolsAsAdmin();
    },
    async getSchoolsAsAdmin() {
      try {
        this.isLoading = true;
        this.schools = await this.$api.getSchoolsAsAdmin();
        console.log(this.schools);
      } catch (err) {
        this.$dialog.fire("Unable to load schools", err.message, "error");
      }
      this.isLoading = false;
    },
    async switchSchool(item) {
      try {
        const response = await this.$dialog.fire({
          title: `Are you sure you want to login to ${item.name}?`,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: this.$vuetify.theme.themes.light.primary,
          cancelButtonColor: this.$vuetify.theme.themes.light.error,
          confirmButtonText: "Yes",
        });
        if (!response.value) {
          return;
        }
        this.isLoading = true;
        const { user, school } = await this.$api.getMyProfileForSchool(
          item.url
        );
        this.$store.commit("setCurrentUser", user);
        this.$store.commit("setCurrentSchool", school);
        if (school?.settings?.colors) {
          this.$vuetify.theme.themes.light = school?.settings?.colors;
        }
      } catch (err) {
        this.$dialog.fire("Unable to your profile", err.message, "error");
      }
      this.isLoading = false;
    },
    async edit(
      school: any,
      type: "admins" | "lecturers" | "students" | "modules"
    ) {
      this.isLoading = true;
      try {
        this.editContent = null;

        if (!school[type]) {
          school[type] = [];
        }
        this.editContent = {
          title: type,
          items: await this.$api.lookupIds(
            type,
            school[type],
            "-file.base64StringFile"
          ),
          headers: this.editContentHeaders[type],
          school: school,
          sample: this.editContentSample[type],
        };
        this.currentSchool = school;
      } catch (err) {
        this.$dialog.fire("Unable to load " + type, err.message, "error");
      }
      this.isLoading = false;
    },
    async editSchool(school: any) {
      this.currentEditSchool = school;
      this.editNewSchool = true;
    },
    async deleteSchool(school: any) {
      const { _id: schoolId, name } = school;

      const response = await this.$dialog.fire({
        title: `Are you sure you want to delete ${name}?`,
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.primary,
        cancelButtonColor: this.$vuetify.theme.themes.light.error,
        confirmButtonText: "Yes",
      });
      if (!response.value) {
        return;
      }

      try {
        this.isLoading = true;
        await this.$api.deleteSchool(schoolId);
        this.reload();
      } catch (err) {
        this.$dialog.fire("Unable to delete school", err.message, "error");
      }
      this.isLoading = false;
    },
  },
});
