import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#f96c5b",
        secondary: "#2d5afd",
        accent: "#8c9eff",
        error: "#b71c1c",
        info: "#00ff00",
        success: "#0000ff",
        warning:"#ffff00",
      },
    },
  },
});
