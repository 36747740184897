import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import Papa from "papaparse";

import vuetify from "./plugins/vuetify";

import moment from "moment";
import "vue-animate/dist/vue-animate.min.css";
import "vue-loaders/dist/vue-loaders.css";
import "emoji-mart-vue/css/emoji-mart.css";
import swal from "sweetalert2";
import HTTP from "./services/http";

const VueSession = require("vue-session");
const { Picker, Emoji } = require("emoji-mart-vue");
const VueTyperPlugin = require("vue-typer");
const VueLoaders = require("vue-loaders");

Vue.component("picker", Picker);
Vue.component("emoji", Emoji);

Vue.config.productionTip = false;
Vue.use(VueTyperPlugin);
Vue.use(VueLoaders);
Vue.use(VueSession, {
  persist: true,
});

Vue.prototype.$api = new HTTP();
Vue.prototype.$dialog = swal;
Vue.prototype.$emoji = Emoji;
Vue.mixin({
  computed:{
    isCurrentLecturer():boolean{
      return this.$store.state.currentSchool && this.$store.state.currentSchool.role == 'LECTURER'
    },
    isCurrentStudent():boolean{
      return this.$store.state.currentSchool && this.$store.state.currentSchool.role == 'STUDENT'
    },
    isCurrentAdmin():boolean{
      return this.$store.state.currentSchool && this.$store.state.currentSchool.role == 'ADMIN'
    },
  },
  methods: {
    downloadCsvContent(filename:string,data:any[],search:string = ''){
      const csv = Papa.unparse(data.filter(v => !search || search.length == 0 || (v && JSON.stringify(v).toLowerCase().includes(search.toLowerCase()))));

      this.exportToCSV(csv, filename);
    },
    exportToCSV(csvContent: string, fileName: string) {
      const blob = new Blob([csvContent]);

      const a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
      a.download = `${fileName}.csv`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    getMoment(value: Date | undefined) {
      return moment(value);
    },
    goToModule(moduleID: string) {
      this.$router.push(`/module/view/${moduleID}`)
    },
    goToLecturer(lecturerID: string) {
      this.$router.push({
        name: "LecturerList",
        params: {
          lecturerIDs: lecturerID,
        },
      });
    },
    goToStudent(studentID: string) {
      this.$router.push({
        name: "StudentList",
        params: {
          studentIDs: studentID,
        },
      });
    },
    goToSolution(solutionID: string) {
      this.$router.push({
        name: "TestMarks",
        params: {
          solutionId: solutionID,
        },
      });
    },
    goToTakeTest(questionaire: any) {
      this.$router.push({
        name: "TakeTest",
        params: {
          dbQuestionaire: questionaire,
        },
      });
    },
    setATestForModule(moduleID: string) {
      this.$router.push({
        name: "SetTest",
        params: {
          moduleID: moduleID,
        },
      });
    },
    async Login(results: any) {
      let myNetworkId = results.data.user.myNetworkId;
      switch (results.data.userType) {
        case "ADMIN":
          this.$store.commit("login", {
            id: results.data.user._id,
            username: results.data.user.username,
            password: results.data.user.password,
            type: "ADMIN",
            isLoggedIn: true,
          });
          this.$router.push("/");
          break;
        case "LECTURER":
          if (!myNetworkId) {
            try {
              myNetworkId = await this.$api.registerToMyNetwork(
                results.data.user,
                "STUDENT"
              );
              swal.fire(
                `🥳🥳`,
                "You are now registered to use My Network.",
                "success"
              );
            } catch (err) {
              console.log("Unable to register user to my-network", err);
            }
          }
          this.$store.commit("login", {
            id: results.data.user._id,
            username: results.data.user.username,
            password: results.data.user.password,
            type: "LECTURER",
            isLoggedIn: true,
          });
          this.$router.push("/");
          break;
        case "STUDENT":
          if (!myNetworkId) {
            try {
              myNetworkId = await this.$api.registerToMyNetwork(
                results.data.user,
                "STUDENT"
              );
              swal.fire(
                `🥳🥳`,
                "You are now registered to use My Network.",
                "success"
              );
            } catch (err) {
              console.log("Unable to register user to my-network", err);
            }
          }
          this.$store.commit("login", {
            myNetworkId,
            id: results.data.user._id,
            username: results.data.user.username,
            password: results.data.user.password,
            type: "STUDENT",
            isLoggedIn: true,
          });
          this.$router.push("/");
          break;
        case "PARENT": {
          this.$store.commit("setParent", true);
          this.$store.commit("setStudentParent", results.data.user);
          this.$store.commit("setParentStudents", results.data.students);
          const user = results.data.students[0];
          this.$store.commit("login", {
            id: user._id,
            username: user.username,
            password: user.password,
            type: "STUDENT",
            isLoggedIn: true,
          });
          this.$router.push("/");
        }
      }
      this.$session.start();
      let newUser: any = null;
      if (results.data.userType === "PARENT") {
        newUser = {
          type: results.data.userType,
          results: results,
        };
      } else {
        newUser = {
          id: results.data.user._id,
          username: results.data.user.username,
          type: results.data.userType,
          isLoggedIn: true,
        };
      }
      this.$session.set("user", newUser);
      var users = this.$session.get("users");
      if (users == null) {
        users = new Array();
      }
      // unique
      users = users.filter((user: { id: string }) => user.id != newUser.id);
      users.push(newUser);
      this.$session.set("users", users);
    },
    logout() {
      this.$session.remove("user");
      this.$store.commit("logout");
      this.$router.push("/login");
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  async created(){
    await store.restored;
    if(store.state.currentSchool?.settings?.colors){
      this.$vuetify.theme.themes.light = store.state.currentSchool?.settings?.colors;
    }
  },
  render: (h) => h(App),
}).$mount("#app");
