
import Vue from "vue";
import CreateMyNetwork from "./CreateMyNetwork.vue";

export default Vue.extend({
  name: "MyNetworkSingleView",
  data() {
    return {
      selectedModules: [],
      allModules: [],
      isLoading: false,
      page: 0,
      post: null,
      showCreateMyNetwork: false,
    };
  },
  props: {
    myNetworkId: String,
  },
  watch: {
    myNetworkId(newVal, oldVal) {
      if (newVal != oldVal) {
        this.refresh();
      }
    },
  },
  components: {
    CreateMyNetwork,
  },
  mounted() {
    this.refresh();
  },
  computed: {
    likesAndComments() {
      return this.post.likes
        .map((v) => ({
          ...v,
          message: "👍🏽",
        }))
        .concat(this.post.comments)
        .sort((a, b) => (this.getMoment(b.date).isAfter(a.date) ? 1 : -1));
    },
  },
  methods: {
    copyLinkToPost(postId) {
      let url = window.location.href;
      if (!url.includes(postId)) {
        url += `/view/${postId}`;
      }
      if (!navigator.clipboard) {
        alert(url);
        return;
      }
      navigator.clipboard.writeText(url);
      this.$dialog.fire({
        position: "bottom-center",
        showConfirmButton: false,
        timer: 3500,
        title: "Link copied to clipboard",
        text: url,
        icon: "success",
      });
    },
    async refresh() {
      this.isLoading = true;
      try {
        this.post = await this.$api.getMyNetworkById({
          myNetworkId: this.myNetworkId,
        });
        console.log(this.post);
        const user = this.$store.state.currentUser;
        const userString = user
          ? `${user.firstname} ${user.lastname}`
          : "Anonymus";

        this.$api.createNewUsageLog({
          schoolId: this.$store.state.currentSchool?._id,
          platform: "WEB",
          event: "VISITED_MY_NETWORK",
          description: `${userString} viewed post ${this.post.title}`,
          metadata: {
            userId: this.$store.state.currentUser?._id,
            myNetworkId: this.post._id,
          },
        });
      } catch (err) {
        this.$dialog.fire({
          title: err.message,
          text: "Unable to get post",
          icon: "error",
        });
      }
      this.isLoading = false;
    },
    downloadFile(ftpId, title) {
      const url = this.$api.getDownloadURL(ftpId);

      this.$api.createNewUsageLog({
        schoolId: this.$store.state.currentSchool?._id,
        platform: "WEB",
        event: "DOWNLOADED_CONTENT",
        description: `Downloaded a file, via MyNetwork ${title}`,
        metadata: {
          url: url,
          ftpId: ftpId,
        },
      });

      const a = document.createElement("a");
      a.target = "_blank";
      a.href = url;
      a.click();
      a.remove();
    },
    async like(post) {
      const index = post.likes.findIndex(
        (v) => v && v.user && v.user._id == this.$store.state.currentUser?._id
      );
      if (index >= 0) {
        post.likes.splice(index, 1);
      } else {
        post.likes.unshift({
          date: new Date(),
          role: this.$store.state.currentSchool?.role,
          school: this.$store.state.currentSchool,
          user: this.$store.state.currentUser,
        });
      }

      try {
        await this.$api.addLikeToMyNetwork({
          myNetworkId: post._id,
          userRole: this.$store.state.currentSchool?.role,
          schoolId: this.$store.state.currentSchool?._id,
        });
      } catch (err) {
        console.log({ err });
      }
    },
    async comment(post) {
      if (post.newComment.length < 2) {
        return;
      }
      const comment = post.newComment;
      post.newComment = "";
      post.comments.unshift({
        date: new Date(),
        role: this.$store.state.currentSchool?.role,
        school: this.$store.state.currentSchool,
        user: this.$store.state.currentUser,
        message: comment,
      });
      try {
        await this.$api.addCommentToMyNetwork({
          myNetworkId: post._id,
          message: comment,
          userRole: this.$store.state.currentSchool?.role,
          schoolId: this.$store.state.currentSchool?._id,
        });
      } catch (err) {
        console.log({ err });
      }
    },
    async deletePost(postId) {
      const response = await this.$dialog.fire({
        title: `Are you sure?`,
        text: "This can not be reverted",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.primary,
        cancelButtonColor: this.$vuetify.theme.themes.light.error,
        confirmButtonText: "Yes",
      });
      if (!response.value) {
        return;
      }

      try {
        const index = this.posts.findIndex((v) => v._id == postId);
        if (index >= 0) {
          this.posts.splice(index, 1);
        }
        this.isLoading = true;
        await this.$api.deleteMyNetworkById({
          myNetworkId: postId,
        });
      } catch (err) {
        this.$dialog.fire({
          title: "Unable to delete post",
          message: err.message,
        });
      }
      this.isLoading = false;
    },
  },
});
