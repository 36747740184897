
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      isLoading: false,
      allModules: [],
      announcement: {
        title: "",
        message: "",
        isToParent: false,
        moduleIds: [],
      },
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.getAllMyModules();
    },
    async sendAnnouncement() {
      if (this.announcement.title.length < 3) {
        return this.$dialog.fire(
          "Enter a title for your announcement",
          "",
          "error"
        );
      }
      if (
        this.announcement.message.length < 3 ||
        this.announcement.message.length > 270
      ) {
        return this.$dialog.fire(
          "Your message should be less than 270 characters but more than 3 characters long",
          "",
          "error"
        );
      }
      if (!this.$store.state.currentUser) {
        return this.$dialog.fire(
          "Unable to submit announcement",
          "You have to be logged in to perform this action",
          "error"
        );
      }
      if (!this.$store.state.currentSchool) {
        return this.$dialog.fire(
          "Unable to submit announcement",
          "A school must be selected to perform this action",
          "error"
        );
      }
      if (!this.isCurrentLecturer && !this.isCurrentAdmin) {
        return this.$dialog.fire(
          "Unable to submit announcement",
          "You do not have permissions to proceed.",
          "error"
        );
      }
      this.isLoading = true;
      try {
        const announcementId = await this.$api.createNewAnnouncement({
          ...this.announcement,
          schoolUrl: this.$store.state.currentSchool.url,
        });

        this.$api.createNewUsageLog({
          schoolId: this.$store.state.currentSchool?._id,
          platform: "WEB",
          event: "ADDED_ANNOUNCEMENT",
          description: `Sent an announcement - ${this.announcement.title}`,
          metadata: {
            announcementId: announcementId,
            moduleIds: this.announcement.moduleIds,
          },
        });

        this.$dialog.fire("Submitted!", "", "success");
      } catch (err) {
        this.$dialog.fire(
          "Unable to submit announcement",
          err.message,
          "error"
        );
      }
      this.isLoading = false;
    },
    async getAllMyModules() {
      try {
        if (!this.$store.state.currentUser) {
          return this.$dialog.fire(
            "Unable to get modules",
            "You have to be logged in to perform this action",
            "error"
          );
        }
        if (!this.$store.state.currentSchool) {
          return this.$dialog.fire(
            "Unable to get modules",
            "A school must be selected to perform this action",
            "error"
          );
        }
        this.isLoading = true;
        const modules = await this.$api.getAllMyModules({
          schoolUrl: this.$store.state.currentSchool.url,
          filter: "name code description",
        });

        this.allModules = modules.map((v) => ({
          ...v,
          title: `${v.name} (${v.code})`,
        }));
        this.isLoading = false;
      } catch (err) {
        this.$dialog.fire("Unable to load modules", err.message, "error");
        this.isLoading = false;
      }
    },
  },
});
