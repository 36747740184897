
import Vue from "vue";
import QuestionaireSolutionView from "./QuestionaireSolutionView.vue";
import TakeTest from "../TakeTest.vue";

export default Vue.extend({
  name: "QuestionaireSolutions",
  components: {
    QuestionaireSolutionView,
    TakeTest,
  },
  data() {
    return {
      isLoading: false,
      txtSearch: "",
      solutions: [],
      currentSolution: null,
      dbQuestionaire: null,
      currentPage: 0,
    };
  },
  mounted() {
    this.refresh();
  },
  props: {
    questionaireId: String,
    title: String,
  },
  watch: {
    questionaireId(newVal, oldVal) {
      this.refresh();
    },
  },
  computed: {
    filteredSolutions(): Array<any> {
      return this.solutions.filter(
        (m) =>
          this.txtSearch.length < 1 ||
          JSON.stringify(m)
            .toLowerCase()
            .indexOf(this.txtSearch.toLowerCase()) >= 0
      );
    },
  },
  methods: {
    refresh() {
      this.getQuestionaireSolutions();
    },
    async takeTest() {
      this.isLoading = true;
      try {
        const [questionaire] = await this.$api.lookupIds(
          "questionaires",
          [this.questionaireId],
          ""
        );
        this.dbQuestionaire = questionaire;
        this.currentPage = 1;
      } catch (err) {
        this.$dialog.fire("Unable to load", err.message, "error");
      }
      this.isLoading = false;
    },
    closedDialog(mustRefresh: boolean) {
      this.currentSolution = null;
      if (mustRefresh) {
        this.refresh();
      }
    },
    openSolution(solution) {
      this.currentSolution = {
        ...solution,
        questionaireId: this.questionaireId,
      };
    },
    async getQuestionaireSolutions() {
      try {
        if (!this.questionaireId) {
          return this.$dialog.fire(
            "Unable to load",
            "A valid questionaire is required",
            "error"
          );
        }
        if (!this.$store.state.currentUser) {
          return this.$dialog.fire(
            "Unable to get module",
            "You have to be logged in to perform this action",
            "error"
          );
        }
        if (!this.$store.state.currentSchool) {
          return this.$dialog.fire(
            "Unable to get module",
            "A school must be selected",
            "error"
          );
        }

        const allOrMy =
          this.$store.state.currentSchool.role == "LECTURER" ||
          this.$store.state.currentSchool.role == "ADMIN"
            ? "all"
            : "my";

        this.isLoading = true;

        this.solutions = await this.$api.getQuestionaireSolutions(
          this.questionaireId,
          allOrMy
        );
        // LATER
        //this.goToTakeTest(questionaire);
        //this.goToSolution(results.data.id);
      } catch (err) {
        this.$dialog.fire("Unable to load", err.message, "error");
      }
      this.isLoading = false;
    },
  },
});
