<template>
  <v-row class="row">
    <v-col cols="12" md="4" offset-md="4" class="my-auto" v-show="done">
      <v-card class="ma-5">
        <v-card-text>
          <p class="title text-center mx-auto">
            Student Parent was successfully added.
          </p>
          <p class="subtitle text-center mx-auto">
            You can also assign the student to more modules / view the student's progress.
          </p>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="mx-auto" v-show="!done">
      <v-content class="card-content">
        <v-row>
          <v-col v-if="students.length > 0" cols="8" class="mx-auto my-3">
            <v-btn :loading="loadingCsv" outlined block color="secondary" @click="uploadBulkFromCSV()">Upload from CSV</v-btn>
            <input @change="selectedFile" ref="select_csv" type="file" id="file" style="display: none;" />
          </v-col>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="students" fixed-header disable-sort :loading="loadingCsv || isLoading" class="elevation-1">
              <template v-slot:item.isValidStudent="{ item }">
                <v-icon :color="item.isValidStudent ? 'secondary' : 'error'">
                  {{ item.isValidStudent ? "mdi-check-decagram" : "mdi-alert-decagram" }}
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-col cols="8" class="mx-auto my-3">
                  <v-btn :loading="loadingCsv" outlined block color="secondary" @click="uploadBulkFromCSV()">Upload from CSV</v-btn>
                  <input @change="selectedFile" ref="select_csv" type="file" id="file" style="display: none;" />
                </v-col>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" md="8" offset-md="2">
            <v-btn @click="saveStudentsParents()" block color="secondary" large :loading="isLoading">Save Parents</v-btn>
          </v-col>
        </v-row>
      </v-content>
    </v-col>
  </v-row>
</template>

<script>
import swal from "sweetalert2";
import * as moment from "moment";
import Papa from "papaparse";

const axios = require("axios");

export default {
  name: "AddStudentParents",
  data() {
    return {
      tab: "tab-single",
      headers: [{ value: "isValidStudent", text: "Valid" }, { value: "surname", text: "Surname" }, { value: "firstName", text: "Name" }, { value: "cellphone", text: "Cellphone" }, { value: "fatherSurname", text: "Father's Surname" }, { value: "fatherName", text: "Father's Name" }, { value: "fathercellphone", text: "Father's Cellphone" }, { value: "fatherEmail", text: "Father's Email" }, { value: "motherSurname", text: "Mother's Surname" }, { value: "motherName", text: "Mother's Name" }, { value: "mothercellphone", text: "Mother's Cellphone" }, { value: "motherEmail", text: "Mother's Email" }],
      students: [],
      showPassword: false,
      txtError: "",
      student: {
        firstname: "",
        lastname: "",
        username: "",
        password: "",
        confirmPassword: "",
        modules: [null],
        idNumber: "",
        gender: "",
        dob: "",
        isSouthAfrican: false,
      },
      done: false,
      moduleNames: [],
      isLoading: false,
      isSearchingStudents: false,
      loadingCsv: false,
    };
  },
  mounted() {
    this.done = false;
    this.LoadModules();
  },
  methods: {
    saveStudentsParents() {
      this.isLoading = true;
      console.log("actual", this.students.filter((v) => v.isValidStudent));
      axios
        .post(this.$store.state.settings.baseLink + "/s/add/bulk/parents", {
          studentParents: this.students.filter((v) => v.isValidStudent),
        })
        .then((results) => {
          this.isLoading = false;
          swal.fire("", "Students successfully linked to their parents", "success");
          this.$router.back();
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.response != null && err.response.status == 512) {
            swal.fire("", err.response.data, "error");
          } else {
            swal.fire("Unable to save students", "Try again later", "error");
          }
        });
    },
    uploadBulkFromCSV() {
      this.$refs.select_csv.click();
    },
    async selectedFile(arg) {
      if (arg.target.files && arg.target.files.length >= 0) {
        const file = arg.target.files[0];
        if (/text\/.+/.test(file.type)) {
          this.loadingCsv = true;
          const csvString = await this.readFile(file);
          if (!csvString) {
            this.loadingCsv = false;
            return swal.fire("Unable to load selected file", "Your browser does not support file readers", "error");
          }
          const result = Papa.parse(csvString);
          if (result.data && result.data.length > 0) {
            this.students = result.data
              .filter((row) => row && row.length > 0 && row[0] != "surname")
              .map((row, i) => {
                return {
                  isValidStudent: false,
                  surname: row.length >= 0 ? row[0] : "",
                  firstName: row.length >= 1 ? row[1] : "",
                  cellphone: row.length >= 2 ? row[2] : "",
                  fatherSurname: row.length >= 3 ? row[3] : "",
                  fatherName: row.length >= 4 ? row[4] : "",
                  fathercellphone: row.length >= 5 ? row[5] : "",
                  fatherEmail: row.length >= 6 ? row[6] : "",
                  motherSurname: row.length >= 7 ? row[7] : "",
                  motherName: row.length >= 8 ? row[8] : "",
                  mothercellphone: row.length >= 9 ? row[9] : "",
                  motherEmail: row.length >= 10 ? row[10] : "",
                };
              })
              .filter((v, i, a) => a.indexOf(v) == i);
            try {
              const { data: foundStudents } = await axios.post(this.$store.state.settings.baseLink + "/s/search/for/bulk/students", {
                students: this.students,
              });
              console.log("here", foundStudents);
              this.students.forEach((student) => {
                if (foundStudents.some((v) => (new RegExp(v.lastname, "i").test(student.surname) && new RegExp(v.firstname, "i").test(student.firstName)) || (student.cellphone && student.cellphone == v.contactNumbers))) {
                  student.isValidStudent = true;
                }
              });
            } catch (ex) {
              console.log("error", ex);
              swal.fire("Unable to load students from database", "Try again later", "error");
            }
            console.log("ALl students...", this.students);
            this.$forceUpdate();
          }
          this.loadingCsv = false;
        } else {
          swal.fire("Unable to load selected file", "We only support .csv files, you selected a " + file.type, "error");
        }
      }
    },
    readFile(file) {
      return new Promise((resolve) => {
        try {
          const reader = new FileReader();
          reader.onload = function(e) {
            return resolve(e.target.result);
          };
          reader.readAsText(file);
        } catch (err) {
          return resolve(null);
        }
      });
    },
    LoadModules() {
      this.isLoading = true;
      axios
        .get(this.$store.state.settings.baseLink + "/m/get/all/module/names")
        .then((results) => {
          this.isLoading = false;
          this.moduleNames = results.data;
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.response != null && err.response.status == 512) {
            swal.fire("", err.response.data, "error");
          } else {
            swal.fire("Unable to load modules", "Try again later", "error");
          }
        });
    },
    SubmitStudent() {
      this.txtError = "";
      this.isLoading = true;
      if (this.student.lastname.length < 2) {
        this.txtError = "Please enter a valid lastname";
      }

      if (this.student.firstname.length < 2) {
        this.txtError = "Please enter a valid firstname";
      }

      if (this.student.password != this.student.confirmPassword) {
        this.txtError = "Passwords do not match";
      }

      if (this.student.password.length < 6) {
        this.txtError = "Please enter a valid password , passwords must be more than 6 charactors long";
      }
      if (this.student.username.length < 2) {
        this.txtError = "Please enter a valid username";
      }

      if (this.student.gender.length < 2) {
        this.txtError = "Please pick a valid gender";
      }

      if (this.student.dob.length < 2) {
        this.txtError = "Please pick a valid date of birth";
      }

      if (this.student.idNumber < 6) {
        this.txtError = "Please enter a valid id number";
      }

      if (this.student.modules.filter((m) => m != null).length == 0) {
        this.txtError = "Please select at least one module";
      }

      if (this.txtError.length > 2) {
        this.isLoading = false;
        return;
      }

      axios
        .post(this.$store.state.settings.baseLink + "/a/add/student", {
          student: this.student,
        })
        .then((results) => {
          this.isLoading = false;
          this.done = true;
          this.$emit("submitted", true);
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.response != null && err.response.status == 512) {
            this.txtError = err.response.data;
          } else {
            swal.fire("Unable to submit the student", err.message, "error");
          }
          this.$emit("submitted", false);
        });
    },
  },
};
</script>

<style scoped>
.cardBar {
  background-color: black;
  color: white;
  width: 100%;
}
</style>
